import { Injectable } from '@angular/core';
import * as crypto from "crypto-js";
import { RSAHelperService } from './rsahelper.service';

@Injectable({
  providedIn: 'root'
})
export class HashedValueService {

  constructor( private readonly rsa: RSAHelperService,) { }


  hashRSA_SHA1(value: string) {
    return this.rsa.encryptWithPublicKey(crypto.SHA1(value).toString());
   }
 
   hashSHA1(value: string) {
     return crypto.SHA1(value).toString();
    }
  
}
