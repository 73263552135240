import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
  path: string;
  ERROR_403 = 'La url solicitada no se encuentra.';

  constructor(private route: ActivatedRoute, private readonly router: Router) { }

  ngOnInit() {
    this.route.data.pipe(take(1))
      .subscribe((data: { path: string }) => {
        // console.log(data, 'data');
       
      });
  }

  redictPage() {
    this.router.navigate(['']);
  }
}
