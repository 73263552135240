
        
<div>
    <app-progress-bar></app-progress-bar>
    
    <div class="page-wrapper">
        <app-header (rightSidebarEvent)="rightSidebar($event)"></app-header>
        <div class="page-body-wrapper">
            <div class="page-sidebar" [class.open]="navServices.collapseSidebar">
                <app-sidebar></app-sidebar>
            </div>
            <div class="page-body">
                <app-breadcrumb></app-breadcrumb>
                <div  class="page-header">
                    <main  > <!--[@animateRoute]="getRouterOutletState(o)"-->
                        <router-outlet #o="outlet"></router-outlet>
                    </main>
                </div>
            </div>
            <footer class="footer">
                <app-footer></app-footer>
            </footer>
        </div>
    </div>
</div>