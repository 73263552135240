import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { authGuard } from 'src/app/core/guards/auth';


const routes: Routes = [
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'recover-password',
    // canActivate: [authGuard],
    component: RecoverPasswordComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
