<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <app-svg-img></app-svg-img>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink>Iniciar sesión</a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="loginForm" 
                                        class="form-horizontal auth-form" novalidate="">
                                        <div class="form-group">
                                            <input formControlName="email" name="login[email]" type="email"
                                                class="form-control" placeholder="Email"
                                                id="exampleInputEmail1">
                                               <div *ngIf="c.email.invalid && (c.email.dirty || c.email.touched || submittedLogin)"
                                                    class="alert">
                                                    <div *ngIf="c.email.errors?.required">
                                                        Email es requerido
                                                    </div>
                                                    <div *ngIf="c.email.errors?.email">
                                                        Email incorrecto
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="form-group">
                                            <input formControlName="password" name="login[password]" type="password"
                                                class="form-control" placeholder="Password">
                                                <div *ngIf="c.password.invalid && (c.password.dirty || c.password.touched || submittedLogin)"
                                                    class="alert">
                                                    <div *ngIf="c.password.errors?.required">
                                                        Password es requerido
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="form-terms">
                                            <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                <input type="checkbox" class="custom-control-input me-2"
                                                    id="customControlAutosizing">
                                                <label class="custom-control-label"
                                                    for="customControlAutosizing">Recordar</label>
                                                <a href="javascript:void(0)" [routerLink]="['/auth/recover-password']"
                                                    class="btn btn-default forgot-pass ms-auto">Olvidé mi contraseña</a>
                                            </div>
                                        </div>
                                        <div class="form-button">
                                            <button [disabled]="seddingLogin" (click)="onLoginFormSubmitted()" class="btn btn-primary" type="button"> Iniciar sesión</button>
                                        </div>
                                    </form>
                                </div>
                                  </ng-template>
                                </li>
                               <li [ngbNavItem]="2">
                                  <a ngbNavLink>Registro</a>
                                  <ng-template ngbNavContent>
                                     <form [formGroup]="registerForm" 
                                    class="form-horizontal auth-form" novalidate>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input formControlName="firstname"  name="firstname" type="text"
                                                    class="form-control" placeholder="Nombre"
                                                    id="exampleInputName">
                                                    <div *ngIf="r.firstname.invalid && (r.firstname.dirty || r.firstname.touched || submittedRegister)"
                                                        class="alert">
                                                        <div *ngIf="r.firstname.errors?.required">
                                                                Nombre es requerido
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input formControlName="lastname"  name="lastname" type="text"
                                                    class="form-control" placeholder="Apellidos"
                                                    id="exampleInputLastName">
                                                    <div *ngIf="r.lastname.invalid && (r.lastname.dirty || r.lastname.touched || submittedRegister)"
                                                        class="alert">
                                                        <div *ngIf="r.lastname.errors?.required">
                                                               Apellido es requerido
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input formControlName="email"  name="email" type="email"
                                            class="form-control" placeholder="E-mail">
                                            <div *ngIf="r.email.invalid && (r.email.dirty || r.email.touched || submittedRegister)"
                                                class="alert">
                                                <div *ngIf="r.email.errors?.required">
                                                        Email es requerido
                                                </div>
                                                <div *ngIf="r.email.errors?.email">
                                                    Email incorrecto
                                                </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input formControlName="password" name="password" type="password"
                                                    class="form-control" placeholder="Password">
                                                    <div *ngIf="r.password.invalid && (r.password.dirty || r.password.touched || submittedRegister)"
                                                        class="alert">
                                                        <div *ngIf="r.password.errors?.required">
                                                            Password es requerido
                                                        </div>
                                                        <div *ngIf="r.password.errors?.['pattern']">
                                                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                                                          </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input formControlName="confirmPassword" name="confirmPassword" type="password"
                                                    class="form-control" placeholder="Confirmar password">
                                                    <div *ngIf="r.confirmPassword.invalid && (r.confirmPassword.dirty || r.confirmPassword.touched || submittedRegister)"
                                                        class="alert">
                                                        <div *ngIf="r.confirmPassword.errors?.required">
                                                                Confirmar password es requerido
                                                        </div>
                                                        <div *ngIf="r.confirmPassword.errors?.['pattern']">
                                                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                                                        </div>
                                                        <div *ngIf="r.confirmPassword.errors?.['confirmedValidator']">
                                                            Las contraseñas no coinciden
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-button">
                                        <button [disabled]="seddingRegister" (click)="onRegisterFormSubmitted()" class="btn btn-primary" type="button">Registrarme</button>
                                    </div>
                                     </form>
                                  </ng-template>
                                </li>
                              </ul>
                              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>