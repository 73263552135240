import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TypeClubComponent } from './type-club/type-club.component';
import { EventCategoryComponent } from './event-category/event-category.component';
import { CitiesComponent } from './cities/cities.component';


const routes: Routes = [
  {
    path: 'type-club',
    component: TypeClubComponent,
    data: {
      title: "Tipos de club",
      breadcrumb: "Tipo de clubs"
    }
  },
  {
    path: 'type-event',
    component: EventCategoryComponent,
    data: {
      title: "Tipos de evento",
      breadcrumb: "Tipo de eventos"
    }
  },
  {
    path: 'cities',
    component: CitiesComponent,
    data: {
      title: "Ciudades",
      breadcrumb: "Ciudades"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
