

import { DestroyRef, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { IS_PUBLIC, IS_LOGIN } from 'src/app/core/interceptor/auth.interceptor';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";


//interface
import { ILogin, IUser, LoginResponse } from 'src/app/interface/auth';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly CONTEXT = { context: new HttpContext().set(IS_LOGIN, true) };
  private readonly CONTEXT_PUBLIC = { context: new HttpContext().set(IS_PUBLIC, true) };

  constructor(private readonly http: HttpClient,
    private readonly router: Router,


    private readonly destroyRef: DestroyRef) { }


  login(body: ILogin): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`security/${environment.apiVersion}/signin`, body, this.CONTEXT);
  }

  logout(): void {
    this.http.post<LoginResponse>(`security/${environment.apiVersion}/signout`, this.CONTEXT_PUBLIC)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        sessionStorage.clear();
        this.router.navigate(['auth/login']);
      });
  }
}