import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { AssociateListComponent } from './components/associate-list/associate-list.component';
import { AddAssociateComponent } from './components/add-associate/add-associate.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'associate-list',
        component: AssociateListComponent,
        data: {
          title: "Lista de asociados",
          breadcrumb: "LISTA DE ASOCIADOS"
        }
      },
      {
        path: 'detail/:id',
        component: AddAssociateComponent,
        data: {
          title: "Detalle del asociado",
          breadcrumb: "Detalle del asociado"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssociatesRoutingModule { }
