
<div class="content-detail">
    <h1 class="global-title"><span>4</span><span>0</span><span>4</span></h1>
    <h4 class="sub-title">Oops!</h4>
    <p class="detail-text">Hola, {{ERROR_403}}</p> 
    <div class="back-btn">
        <a (click)="redictPage()" class="btn btn-default">Regresar al incio</a>
    </div>
</div>

