import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from "@angular/common/http";
import { Observable, catchError, of, tap } from "rxjs";
import { IS_PUBLIC } from 'src/app/core/interceptor/auth.interceptor';
//interface
import { IRegisterSuccess, RegisterResponse } from 'src/app/interface/auth';

import { environment } from 'src/environments/environment';
import { UpdateTokenService } from './update-tokens.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {
    private readonly CONTEXT = { context: new HttpContext().set(IS_PUBLIC, true) };

    constructor(private readonly http: HttpClient,
        private jwtHelper: JwtHelperService,
        private readonly router: Router,
        private updateToken: UpdateTokenService) { }


    refreshToken(): Observable<RegisterResponse | null> {
        const _token = this.updateToken.getToken();
        // console.log(_token, '_token');
        if (_token) {
            const expired = this.jwtHelper.isTokenExpired(_token);
            // console.log(expired, 'expired');
            if (expired) {
                return this.getRefreshToken();
            } else {
                const { pathname } = window.location;
                // console.log(pathname,  window.location);
                if(pathname === "/auth/login") {
                    this.router.navigate(['/dashboard']);
                }
                of();
            }
        } else {
            return this.getRefreshToken();
        }
    }

    getRefreshToken(): Observable<any> {
        this.router.navigate(['/auth/login']);
        return of();
        console.log('refresh token ');
        // return;
        // const token = this.updateToken.getToken();
        // return this.http.post<RegisterResponse>(
        //     `${environment.apiUrl}/token/refresh`, { token }, this.CONTEXT)
        //     .pipe(
        //         catchError(() => of()),
        //         tap(data => {
        //             const loginSuccessData = data as IRegisterSuccess;
        //             this.updateToken.setTokens(loginSuccessData);
        //         })
        //     );
    }
}

