import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest
} from "@angular/common/http";
import { throwError } from "rxjs";
import { inject } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { catchError } from 'rxjs';

import { retry } from "rxjs/operators";
import { Router } from "@angular/router";


export const httpErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const toast = inject(ToastrService);
  const router = inject(Router);
  return next(req).pipe(
    retry(1),
    catchError((res: any) => {
      const { error, status } = res;
      if (Number(status) === 400) {
        toast.error(error.message, `Error: ${error.code || 'en el servicio'}`);
        return throwError(error);
      }
      else if (Number(status) === 404) {
        toast.error(error.message, `Error: ${error.code || 'No se econtró el servicio'}`);
      } else if (Number(status) === 500) {
        toast.error(error.message, `Error: ${error.code || 'Lo sentimos ocurrío un error al procesar su información.'}`);
        return throwError(res);
      } else if (Number(status) === 422) {
        toast.error(error.message, `Error: ${error.code || 'Los datos ingresados no son suficientes para procesar su información, revise el formulario por favor.'}`);
        router.navigate(['/auth/login']);
      } else if (Number(status) === 401) {
        router.navigate(['/auth/login']);
        toast.error(error.message, `Error: ${error.code || 'Acceso no autorizado.'}`);
        return throwError(res);
      } else if (Number(status) === 403) {
        toast.error(error.message, `Error: ${error.code || 'Solicitud no disponible.'}`);
        return throwError(res);
      }
      let message = '';
      if (error instanceof ErrorEvent) {
        message = `Error: ${error.message}`;
      } else {
        message = `Error Status: ${status}\nMessage: ${message}`;
      }
      return throwError(message);
    })
  );

}