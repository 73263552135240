import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const basePathInterceptor: HttpInterceptorFn = (req, next) => {

  const usrl: string[] = req.url.split('/');
  let urlService = '';
  // apiSecurity: 'http://18.191.157.138:1001/api/',///security
  // apiSetting: 'http://18.191.157.138:1002/api/', ///setting
  // apiUser: 'http://18.191.157.138:1003/api/', ///user
  // apiClub: 'http://18.191.157.138:1004/api/',///club

  if (Array.isArray(usrl) && !!usrl.length) {
    switch (usrl[0]) {
      case 'security':
        urlService = environment.apiSecurity;
        break;
      case 'setting':
        urlService = environment.apiSetting;
        break;
      case 'user':
        urlService = environment.apiUser;
        break;
      case 'club':
        urlService = environment.apiClub;
        break;
      default:
        break;
    }
  }
  if (!urlService) {
    return;
  }
  const requestWithBasepath = req.clone({
    url: `${urlService}/${req.url}`,
  });

  return next(requestWithBasepath);

};
