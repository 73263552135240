import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AssociatesRoutingModule } from './associates-routing.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { NgxDropzoneModule } from 'ngx-dropzone';
// search module
import { SharedModule } from 'src/app/shared/shared.module';

import { AddAssociateComponent } from './components/add-associate/add-associate.component';
import { AssociateListComponent } from './components/associate-list/associate-list.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedComponentsModule } from '../shared/shared-components.module';

@NgModule({
  declarations: [
    AddAssociateComponent,
    AssociateListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AssociatesRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    SharedComponentsModule
  ],
  exports: [],
  providers: [
    NgbActiveModal,
    
  ]
})
export class AssociatesModule { }
