import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch-status',
  templateUrl: './switch-status.component.html',
  styleUrl: './switch-status.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchStatusComponent),
      multi: true,
    },
  ],
})
export class SwitchStatusComponent implements OnInit, ControlValueAccessor {

  @Input() options: {id: string, textCheck: string, textUnCheck: string};
  public statusAux: boolean;

  ngOnInit(): void {

  }
  // formControlName="status"
  onChange: (value: Boolean) => void;

  onTouch: () => void;

  writeValue(status: boolean): void {
    this.statusAux = status;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // console.log(isDisabled, "isDisabled");
  }

  updateStatus(checked: boolean) {
    this.onChange(checked);
  }

}
