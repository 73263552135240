import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { RegisterService } from 'src/app/core/services/api/auth/register.service';
import { ConfirmedValidator, regexPassword } from 'src/app/core/services/local/confirm-password';
import { HashedValueService } from 'src/app/core/services/local/hashed-value.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent {

  //forms
  public form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]]
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });
  submitted: boolean;
  sedding: boolean;

  constructor(private readonly fb: FormBuilder,
    private readonly toast: ToastrService,
    private readonly regSvc: RegisterService,
    private readonly destroyRef: DestroyRef,
    private readonly hashed: HashedValueService
  ) { }

  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;

    const { email, password, confirmPassword } = this.form.value;
    const model = {
      email: email,
      password: this.hashed.hashRSA_SHA1(password),
      confirmPassword: this.hashed.hashRSA_SHA1(confirmPassword)
    };

    this.sedding = true;
    this.regSvc.recoverPassword(model)
      .pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
      });
  }


  get c() {
    return this.form.controls;
  }

}
