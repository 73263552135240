import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesComponent } from './components/images/images.component';
import { SwitchStatusComponent } from './components/switch-status/switch-status.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ImagesComponent, SwitchStatusComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [ImagesComponent, SwitchStatusComponent]
})
export class SharedComponentsModule { }
