<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Type Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button"  class="btn btn-primary" data-original-title="test"
                        data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="openModal(content)"><i class="fa fa-plus" aria-hidden="true"></i> Agregar</button>
                    </div>
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#Id</th>
                                    <th scope="col" sortable="product_name" (sort)="onSort($event)">Nombre</th>
                                    <th scope="col" (sort)="onSort($event)" >Descripción</th>
                                    <th scope="col" class="tex-center">Estado</th>
                                    <th scope="col" class="tex-center" (sort)="onSort($event)">Opciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let event of tableItem$ | async; let i=index">
                                <th  scope="row"><a style="color: #ff5370 !important;" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="openModal(content, event)">#{{ i +1 }}</a></th>
                                <td>{{event.name}}</td>
                                <td>{{event.description}}</td>
                                <td class="tex-center"><span class='badge' [ngClass]="{'badge-success': event.status == 1, 'badge-warning': event.status == 1  }">{{event.status == 1 ?  'Activo' : 'Inactivo' }}</span></td>
                                <td class="tex-center">
                                    <a ngbTooltip="Editar" data-bs-toggle="modal" style="cursor: pointer;" data-bs-target="#exampleModal" (click)="openModal(content, event)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a ngbTooltip="Eliminar" style="cursor: pointer;" (click)="deleteTypeEvents(event)" href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                   (pageChange)="getListEvents($event)"
                                   [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                   </ngb-pagination>
                             </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content  let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModal">{{form.value.id ? 'Editar': 'Agregar'}} tipo de evento</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" [formGroup]="form">
            <div class="form">
                <div class="form-group d-flex justify-content-end">
                    <app-switch-status [options]="{id:'statusCity', textCheck: 'Activo', textUnCheck: 'Inactivo'}" formControlName="status"></app-switch-status>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Nombre :</label>
                    <input formControlName="name" class="form-control " id="validationCustom01" type="text">
                    <div *ngIf="r.name.invalid && (r.name.dirty || r.name.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.name.errors?.required">
                                Nombre es requerido
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Descripción :</label>
                    <div class="description-sm">
                        <textarea formControlName="description"  class="form-control"  rows="5"></textarea>
                        <div *ngIf="r.description.invalid && (r.description.dirty || r.description.touched || submitted)"
                                class="alert-form">
                                <div *ngIf="r.description.errors?.required">
                                    Descripción es requerido
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button"  [disabled]="sedding" (click)="onFormSubmitted()" class="btn btn-primary"><i class="fa fa-floppy-o" aria-hidden="true"></i> GUARDAR</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>