<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">

            <div class="row">
                <div class="col-10">
                    <form [formGroup]="formFilter" class="needs-validation">
                    <div class="row">
                            <div class="col-9 d-flex">
                                <div class="form-group m-1 flex-fill">
                                    <input formControlName="fullname" class="form-control" id="filteruser" placeholder="Ingrese el usuario" type="text">
                                </div>
                                <div class="form-group m-1 flex-fill">
                                    <input formControlName="email" class="form-control" id="filteremail" placeholder="Ingrese E-mail" type="text">
                                </div>
                            </div>
                            <div class="col-3">
                                <button (click)="getListUser()" type="button" class="btn btn-secondary"><i class="fa fa-search" aria-hidden="true"></i></button>
                                <button (click)="resetList()" type="button" class="btn btn-secondary m-1"><i class="fa fa-paint-brush" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-2">
                    <div class="pull-right m-1">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                                    data-target="#procudct-add-user" (click)="openModalUser(contentUser)"><i class="fa fa-plus"></i> Agregar</button>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-body">
            
            <div id="batchDelete" class="category-table custom-datatable">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="fName" (sort)="onSort($event)">#Id</th>
                                <th scope="col" sortable="fName" (sort)="onSort($event)">Nombre</th>
                                <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                                <th scope="col" sortable="role" (sort)="onSort($event)">Rol</th>
                                <th scope="col" sortable="role" (sort)="onSort($event)">Estado</th>
                                <th class="tex-center" scope="col" >Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableItem$ | async ; let i=index">
                                <td><a style="color: #ff5370 !important;" (click)="editUser(item)">#{{ i +1  }}</a></td>
                                <td>
                                    {{item.fullname}}
                                </td>
                                <td>{{item.email}}</td>
                                <td>{{item.roleName}}</td>
                                <td><span class='badge' [ngClass]="{'badge-success': item.status == 1, 'badge-warning': item.status == 2  }">{{item.status == 1 ?  'Activo' : 'Inactivo' }}</span></td>
                                <td class="tex-center">
                                    <a ngbTooltip="Editar" (click)="editUser(item)" href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a ngbTooltip="Cambiar contraseña" (click)="openUpdatePassword(contentUserPassword, item)"  data-target="#procudct-add-user-password" href="javascript:void(0)"><i class="fa fa-unlock-alt"></i></a>&nbsp;
                                    <a ngbTooltip="Eliminar" (click)="deleteCategory(item)" href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          (pageChange)="getListUser($event)"
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- Container-fluid Ends-->
<ng-template #contentUser let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="procudct-add-user">Agregar usuario</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Nombre:</label>
                    <input formControlName="firstname" class="form-control" id="validationCustom01" type="text">
                    <div *ngIf="r.firstname.invalid && (r.firstname.dirty || r.firstname.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.firstname.errors?.required">
                            Ingrese el nombre
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom02" class="mb-1">Apellidos:</label>
                    <input formControlName="lastname" class="form-control" id="validationCustom02" type="text">
                    <div *ngIf="r.lastname.invalid && (r.lastname.dirty || r.lastname.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.lastname.errors?.required">
                               Ingrese los apellidos
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom03" class="mb-1">Email:</label>
                    <input formControlName="email" class="form-control" id="validationCustom03" type="text">
                    <div *ngIf="r.email.invalid && (r.email.dirty || r.email.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.email.errors?.required">
                               Ingrese el E-mail
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom04" class="mb-1">Teléfono:</label>
                    <input formControlName="phone" class="form-control" id="validationCustom04" type="text">
                    <div *ngIf="r.phone.invalid && (r.phone.dirty || r.phone.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.phone.errors?.required">
                               Ingrese el teléfono
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Rol:</label>
                    <select  formControlName="rol" class="custom-select form-select">
                        <option value="">[ Seleccione ]</option>
                        <option *ngFor="let rol of roles" [value]="rol.id">{{rol.name}}</option>
                    </select>
                    <div *ngIf="r.rol.invalid && (r.rol.dirty || r.rol.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.rol.errors?.required">
                                Seleccione el rol
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button  [disabled]="sedding" (click)="onFormSubmitted()" type="button" class="btn btn-primary"><i class="fa fa-floppy-o"></i> GUARDAR</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>



<!-- Container-fluid Ends-->
<ng-template #contentUserPassword let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="procudct-add-user-password">Cambiar contraseña</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formPassword" class="needs-validation">
            <div class="form">

                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"> Password:</label>
                    <input formControlName="password" class="form-control" id="validationCustom01" type="password">
                    <div *ngIf="p.password.invalid && (p.password.dirty || p.password.touched || submittedPassword)"
                    class="alert-form">
                    <div *ngIf="p.password.errors?.required">
                            Ingrese password
                    </div>
                    <div *ngIf="p.password.errors?.['pattern']">
                        Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                    </div>
                </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom02" class="mb-1">Confirmar password :</label>
                    <input formControlName="confirmPassword" class="form-control" id="validationCustom02" type="password">
                    <div *ngIf="p.confirmPassword.invalid && (p.confirmPassword.dirty || p.confirmPassword.touched || submittedPassword)"
                        class="alert-form">
                        <div *ngIf="p.confirmPassword.errors?.required">
                                Ingrese confirmar password
                        </div>
                        <div *ngIf="p.confirmPassword.errors?.['pattern']">
                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                        </div>
                        <div *ngIf="p.confirmPassword.errors?.['confirmedValidator']">
                            Las contraseñas no coinciden
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button [disabled]="seddingPassword" (click)="saveUserAcountPassword()" type="button" class="btn btn-primary"><i class="fa fa-floppy-o"></i> GUARDAR</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>