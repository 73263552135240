import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { SharedModule } from '../../shared/shared.module';

// type clubs
import { TypeClubComponent } from './type-club/type-club.component';
import { EventCategoryComponent } from './event-category/event-category.component';
import { CitiesComponent } from './cities/cities.component';
// import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedComponentsModule } from '../shared/shared-components.module';

@NgModule({
  declarations: [
     TypeClubComponent, 
     EventCategoryComponent, 
     CitiesComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    // NgxDropzoneModule,
    SharedComponentsModule
  ]
})
export class SettingModule { }
