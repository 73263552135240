// import { MANAGER, PARTNER, SUPERUSER } from "src/app/constant/roles.constant";
import { MANAGER, PARTNER, SUPERUSER } from "src/app/constant/type-credentials";
import { IMenu } from "src/app/interface/menu.interface";

export const MENUITEMS: IMenu[] = [
    {
        path: '/dashboard',
        title: 'Home',
        icon: 'home',
        type: 'link',
        badgeType: 'primary',
        active: false,
        role: [SUPERUSER, PARTNER, MANAGER],
    },
    {
        path: '/account/account-list',
        title: 'Mi Cuenta',
        icon: 'user',
        type: 'link',
        active: false,
        role: [SUPERUSER, PARTNER, MANAGER], //, PARTNER, MANAGER]
    },
    {
        path: '/associate/associate-list',
        title: 'Asociados',
        icon: 'users',
        type: 'link',
        active: false,
        role: [SUPERUSER],
    },
    {
        path: '/clubs/clubs-list',
        title: 'Clubs',
        icon: 'tag',
        type: 'link',
        active: false,
        role: [SUPERUSER, PARTNER, MANAGER],
    },
    {
        path: '/events/events-list',
        title: 'Eventos',
        icon: 'layers',
        type: 'link',
        active: false,
        role: [SUPERUSER, PARTNER, MANAGER],
    },
    {
        path: '/users/user-list',
        title: 'Usuarios',
        icon: 'user-plus',
        type: 'link',
        active: false,
        role: [SUPERUSER],
    },
    {
        title: 'Configuraciones',
        icon: 'settings',
        type: 'sub',
        role: [SUPERUSER],//[SUPERUSER, PARTNER, MANAGER],
        children: [
            { path: '/settings/type-club', title: 'Tipos de club', icon: 'bar-chart', type: 'link' },
            { path: '/settings/type-event', title: 'Tipos de evento', icon: 'bar-chart', type: 'link' },
            { path: '/settings/cities', title: 'Ciudades', icon: 'bar-chart', type: 'link' },
        ]
    },
]