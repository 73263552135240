import { createAction, props } from '@ngrx/store';
import { ITypeClub } from 'src/app/modules/setting/interfaces/clubs.interface';
// import { ITypeClub } from 'src/app/shared/tables/type-club';

export const loadTypeClub = createAction(
  '[Type club] Load',
);

export const loadedTypeClub = createAction(
  '[Type club] Loaded success',
  props<{ data: ITypeClub[] }>()
);

