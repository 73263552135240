
<div class="page-main-header" [class.open]="open">
  
    <div class="main-header-right row">

        <div class="main-header-left d-lg-none col">
            <!-- <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                        src="assets/images/dashboard/multikart-logo.png" alt=""></a></div> -->
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <div class="media align-items-center"><img
                            class="align-self-center pull-right img-50 rounded-circle"
                            src="assets/images/user.png" alt="header-user">

                        <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li><a [routerLink]="'/account/add-account/0'">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar cuenta
                            </a>
                        </li>
                        <!-- <li><a href="javascript:void(0)">
                                <app-feather-icons [icon]="'mail'"></app-feather-icons>Inbox
                            </a>
                        </li>
                        <li><a href="javascript:void(0)">
                                <app-feather-icons [icon]="'lock'"></app-feather-icons>Lock Screen
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <app-feather-icons [icon]="'settings'"></app-feather-icons>Settings
                            </a>
                        </li> -->
                        <li>
                            <a (click)="signout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>