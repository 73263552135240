import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAssociate } from 'src/app/modules/associates/interface/associate.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AssociateService {


    private version = environment.apiVersion;
    // private userId = sessionStorage.getItem('id');

    constructor(private readonly http: HttpClient) { }

    //{{mock_api}}/v1/users/{{user_id}}/partners?size=2&page=1
    getListAssociateService(params?: { size: number, page: number, email?: string, fullname?: string }): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        let hhtpParams: HttpParams = new HttpParams()
            .set("page", params ? params.page : 1)
            .set("size", params ? params.size : 100);
        if (params && params.email) {
            hhtpParams = hhtpParams.set("email", params.email);
        }
        if (params && params.fullname) {
            hhtpParams = hhtpParams.set("fullname", params.fullname);
        }
        return this.http.get(`user/${this.version}/users/${userId}/partners`, { params: hhtpParams });
    }

    //{{mock_api}}/v1/users/{{user_id}}/partners
    saveAssociate(model: IAssociate): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.post(`user/${this.version}/users/${userId}/partners`, model);
    }

    //{{mock_api}}/v1/users/{{user_id}}/partners/{{partner_id}}
    getAssociateService(partnerId: string): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.get(`user/${this.version}/users/${userId}/partners/${partnerId}`)
    }

    // {{mock_api}}/v1/users/{{user_id}}/partners/{{partner_id}}
    updateAssociate(associateId: string, data: FormData) {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.patch(`user/${this.version}/users/${userId}/partners/${associateId}`, data);
    }

    // updateCredentials(saveModel: any) {
    //     return of();
    // }


    // {{mock_api}}/v1/users/{{user_id}}/partners/{{partner_id}}
    deleteAssociate(id: string): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.delete(`user/${this.version}/users/${userId}/partners/${id}`)
    }

}