import { Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { AssociateFormService } from 'src/app/core/services/api/associate/associate-form.service';
import { AssociateService } from 'src/app/core/services/api/associate/associate.service';

import { NgbDateFormat } from 'src/app/core/services/local/ngb-date-format';

import { IAssociate } from '../../interface/associate.interface';

@Component({
  selector: 'app-add-associate',
  templateUrl: './add-associate.component.html',
  styleUrls: ['./add-associate.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFormat }]
})
export class AddAssociateComponent implements OnInit {

  public Editor = ClassicEditor;
  public files: File[] = [];
  public associateId: string;
  public form: FormGroup;

  submitted: boolean;
  sedding: boolean;
  eventFiles: boolean;


  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private readonly toast: ToastrService,
    private readonly destroyRef: DestroyRef,
    private readonly associateForm: AssociateFormService,
    private readonly associateService: AssociateService,) {
    this.associateId = route.snapshot.params.id;
  }

  increment() {
    const counter = Number(this.form.value.amount) + 1;
    this.form.get('amount').patchValue(counter);
  }

  decrement() {
    const counter = Number(this.form.value.amount) - 1;
    this.form.get('amount').patchValue(counter);
  }

  updateValue(value: number) {
    // console.log('change')
    this.form.get('amount').patchValue(value);
  }


  ngOnInit() {
    this.eventFiles = false;
    this.form = this.associateForm.form;
    this.getAssociate();
  }

  getAssociate() {
    this.associateService.getAssociateService(this.associateId).subscribe((res: IAssociate) => {
      this.associateForm.setform(res);
      if (res.contract && res.contract.file) {
        this.getFileData(res.contract.file);
      }
    });
  }


  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    let extension: any = dataUrl.split('.');
    let name: any = dataUrl.split('/');
    extension = extension.slice(extension.length - 1, extension.length).join('.');
    name = name.slice(name.length - 1, name.length)
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], name || fileName + `.${extension}`, { type: extension });
  }

  getFileData(url: string) {
    this.dataUrlToFile(url, 'contrata').then((file) => {
      this.files.push(file);
    });
  }


  get r() {
    return this.form.controls;
  }


  formData = (payload: string): FormData => {
    const formData = new FormData();
    if (this.eventFiles) {
      if (Array.isArray(this.files) && !!this.files.length) {
        formData.append('contract', this.files[0]);
      } else {
        // formData.append('contract', null);
      }
    }
    formData.append('payload', payload)
    return formData;
  }

  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;
    const model = this.associateForm.saveForm();
    const data = this.formData(JSON.stringify(model));

    this.sedding = true;
    this.associateService.updateAssociate(this.associateId, data)
      .pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((res: any) => {
        this.sedding = false;
        this.toast.success("Datos actualizados correctamente.");
      });
  }


  // files
  onSelect(event) {
    this.eventFiles = true;
    if (this.files && this.files.length >= 1) {
      this.onRemove(this.files[0]);
    }
    this.files.push(...event.addedFiles);
  }



  onRemove(event) {
    this.eventFiles = true;
    this.files.splice(this.files.indexOf(event), 1);
  }
}
