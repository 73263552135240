import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import type { IAssociate, IAssociateSave } from "src/app/modules/associates/interface/associate.interface";
import { DatesService } from "../../local/dates.service";

@Injectable({
    providedIn: "root",
})
export class AssociateFormService {

    constructor(private fb: FormBuilder,
        private readonly formater: DatesService) { }

    public form: FormGroup = this.fb.group({
        id: ['', [Validators.required]],
        status: [false],
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        cellphone: ['', [Validators.required]],
        address: ['', [Validators.required]],
        price: [''],
        amount: [''],
        dateInit: [null, [Validators.required]]
    });

    // 0: eliminado
    // 1: activo
    // 2: inactivo


    setform(data: IAssociate) {
        this.form.setValue({
            id: data.id,
            status: data && data.status == 1 ? true : false,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            cellphone: data.cellphone || '',
            address: data.address || '',
            price: data.contract && data.contract.price ? data.contract.price : 0,
            amount: data.contract && data.contract.amount ? data.contract.amount : 0,
            dateInit: data.contract && data.contract.dateInit ? this.formater.getDatePicker(data.contract.dateInit): '',
        });
    }

    saveForm(): IAssociateSave {
        const formValue = this.form.value;
        const model: any = {
            status: formValue.status ? 1 : 2,
            firstname: formValue.firstname,
            lastname: formValue.lastname,
            email: formValue.email,
            phone: formValue.cellphone,
            address: formValue.address,
            product: {
                price: formValue.price,
                amount: Number(formValue.amount),
                dateInit: formValue.dateInit ? this.formater.setDate(formValue.dateInit) : ''
            }
        }
        return model;
    }
}


