<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <div class="pull-right">
                        <button [disabled]="sedding" (click)="onFormSubmitted()" type="button" class="btn btn-primary"><i class="fa fa-floppy-o" aria-hidden="true"></i> GUARDAR</button>
                    </div>
                </div> 
                <div class="card-body tab2-card">
                    <form [formGroup]="form" class="needs-validation user-add">
                        <div class="form-group d-flex justify-content-end">
                            <div class="pull-right ">
                                <app-switch-status [options]="{id:'statusUser', textCheck: 'Activo', textUnCheck: 'Inactivo'}" formControlName="status"></app-switch-status>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span>
                                Nombre</label></div>
                            <div class="col-xl-9 col-md-8">
                                <input formControlName="firstname" class="form-control" id="validationCustom0" type="text">
                                <div *ngIf="r.firstname.invalid && (r.firstname.dirty || r.firstname.touched || submitted)"
                                    class="alert-form">
                                    <div *ngIf="r.firstname.errors?.required">
                                        Ingrese el nombre
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom1"><span>*</span>Apellidos</label></div>
                            <div class="col-xl-9 col-md-8">
                                <input formControlName="lastname"  class="form-control" id="validationCustom1" type="text">
                                <div *ngIf="r.lastname.invalid && (r.lastname.dirty || r.lastname.touched || submitted)"
                                    class="alert-form">
                                    <div *ngIf="r.lastname.errors?.required">
                                        Ingrese los apellidos
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                Email</label></div>
                            <div class="col-xl-9 col-md-8">
                                <input formControlName="email"  class="form-control" id="validationCustom2" type="text">
                                <div *ngIf="r.email.invalid && (r.email.dirty || r.email.touched || submitted)"
                                    class="alert-form">
                                    <div *ngIf="r.email.errors?.required">
                                        Ingrese el E-mail
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom2"><span>*</span>
                                Role</label></div>
                            <div class="col-xl-9 col-md-8">
                                <select formControlName="role"  class="custom-select form-select">
                                    <option value="">[     Seleccione       ]</option>
                                    <option *ngFor="let rol of DataRoles" [value]="rol.id">{{rol.name}}</option>
                                </select>
                                <div *ngIf="r.role.invalid && (r.role.dirty || r.role.touched || submitted)"
                                    class="alert-form">
                                    <div *ngIf="r.role.errors?.required">
                                        Seleccione el rol
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->