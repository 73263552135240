import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITypeEvents } from 'src/app/modules/setting/interfaces/events-type.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventsTypeService {

    private version = environment.apiVersion;
    constructor(private readonly http: HttpClient) { }

    //{{mock_api}}/v1/users/{{user_id}}/events?size=2&status=A&page=1
    getListEventsTypeService(data: { size: number; status: string; page: number; }): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        return this.http.get(`setting/${this.version}/users/${userId}/events?size=${data.size}&status=${data.status}&page=${data.page}`);
    }

    // {{mock_api}}/v1/users/{{user_id}}/events/{{event_id}}
    updateTypeEvents(id: any, model: ITypeEvents) {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        return this.http.patch(`setting/${this.version}/users/${userId}/events/${id}`, model);
    }

    //{{mock_api}}/v1/users/{{user_id}}/events
    saveTypeEvents(model: ITypeEvents) {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        return this.http.post(`setting/${this.version}/users/${userId}/events`, model);
    }

    //{{mock_api}}/v1/users/{{user_id}}/events/{{event_id}}
    deleteTypeEvents(id: string) {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        return this.http.delete(`setting/${this.version}/users/${userId}/events/${id}`);
    }
}
