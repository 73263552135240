import { DecimalPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, Observable, throwError } from 'rxjs';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { SweetalerService } from 'src/app/core/services/local/sweetaler.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { IDataB2BPayment, IUser } from '../interface/user.interface';
import { ConfirmedValidator, regexPassword } from 'src/app/core/services/local/confirm-password';
import { HashedValueService } from 'src/app/core/services/local/hashed-value.service';
import { MANAGER } from 'src/app/constant/type-credentials';
import { CrendentialsService } from 'src/app/core/services/api/auth/credentials.service';
import { ToastrService } from 'ngx-toastr';
import { DATA_PARAMS } from 'src/app/shared/data/params-table';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit {
  public roles = [{ id: 1, name: 'super user' }];

  public tableItem$: Observable<any[]>;
  public searchText;
  total$: Observable<number>;

  /*** form filter */
  public formFilter: FormGroup = this.fb.group({
    email: ['', [Validators.email]],
    fullname: ['']
  });


  public form: FormGroup = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]]
  });
  //satatus sedding
  submitted = false;
  sedding: boolean;

  /** password */
  public formPassword: FormGroup = this.fb.group({
    id: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]]
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });

  public submittedPassword: boolean;
  public seddingPassword: boolean;
  /** ends password */


  closeResult: string;

  constructor(public service: TableService,
    private modalService: NgbModal,
    private readonly toast: ToastrService,
    private readonly fb: FormBuilder,
    private readonly crendentialsService: CrendentialsService,
    private readonly sweetalerService: SweetalerService,
    private readonly userService: UserService,
    private readonly hashed: HashedValueService,
    private readonly destroyRef: DestroyRef,
    private router: Router) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  openModalUser(content) {
    this.form.reset();
    this.modalService.open(content, { size: 'lg', backdrop :"static", keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getListUser();
  }

  get r() {
    return this.form.controls;
  }

  // { id: 2, code: "Partner", name: "Asociado" },
  // { id: 3, code: "Manager", name: "Gestor de contencodeo" },
  // { id: 4, code: "Client", name: "Cliente" },
  getListUser(page?: number) {
    const filter = this.formFilter.value;
    const params = { ...DATA_PARAMS, page: page || 1, email: filter.email || "", fullname: filter.fullname || "" };
    this.userService.getListUserService(params).subscribe((res: IDataB2BPayment) => {
      (res.items || []).map((d) => {
        switch (d.role) {
          case 'Partner':
            d.roleName = 'Asociado';
            break;
          case 'Manager':
            d.roleName = 'Gestor de contencodeo';
            break;
          case 'Client':
            d.roleName = 'Cliente';
            break;
        }
        return d;
      });
      this.service.setUserData(res)
    })
  }

  resetList() {
    this.formFilter.reset();
    this.getListUser(1);
  }

  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;
    const model: any = { ...this.form.value };
    this.sedding = true;
    // console.log(model, 'model');
    this.userService.saveUserClub(model).pipe(
      catchError((error: any) => {
        this.sedding = false;
        return throwError('Something went wrong');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: any) => {
      this.sedding = false;
      this.router.navigate(["/users/add-user", res.id]);
      this.modalService.dismissAll();
    });

  }

  editUser(user: IUser) {
    this.router.navigate(["/users/add-user", user.id]);
  }

  deleteCategory(user: IUser) {
    this.sweetalerService.confirm(`Usuario: ${user.name}`)
      .then((result) => {
        if (result.isConfirmed) {
          this.userService.deleteUserService(user.id).pipe(
            catchError((error: any) => {
              // this.sedding = false;
              return throwError('Something went wrong');
            }),
            takeUntilDestroyed(this.destroyRef)
          ).subscribe((res: any) => {
            if (res.id) {
              this.toast.success("Se eliminó el usuario correctamente");
              this.getListUser();
            }
          });
        }
      });
  }


  //#region password

  get p() {
    return this.formPassword.controls;
  }

  openUpdatePassword(content, user) {
    this.formPassword.reset();
    this.formPassword.get('id').patchValue(user.id);
    this.modalService.open(content, { size: 'lg', backdrop :"static", keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  saveUserAcountPassword() {
    this.submittedPassword = true;
    const model = this.formPassword.value;
    // console.log()
    if (!this.formPassword.valid) {
      return;
    }

    this.submittedPassword = false;
    const saveModel = {
      id: model.id,
      confirmPassword: this.hashed.hashRSA_SHA1(model.confirmPassword),
      password: this.hashed.hashRSA_SHA1(model.password),
    }
    this.seddingPassword = true;
    this.crendentialsService.updateCredentials(MANAGER, saveModel).pipe(
      catchError((error: any) => {
        this.sedding = false;
        return throwError('Something went wrong');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: any) => {
      this.seddingPassword = false;
      this.toast.success("Se actualizó la contraseña correctamente");
      this.modalService.dismissAll();
    });
  }

  //#endregion
}

