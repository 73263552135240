import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { authGuard } from 'src/app/core/guards/auth';
import { RecoverEmailComponent } from './recover-email/recover-email.component';
import { RecoverCodeComponent } from './recover-code/recover-code.component';


const routes: Routes = [
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'recovery-email',
    component: RecoverEmailComponent,
  },
  {
    path:'recovery-code/:email',
    component: RecoverCodeComponent,
  },
  {
    path:'recovery-password',
    component: RecoverPasswordComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
