import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {


  private version = environment.apiVersion;
  constructor(private readonly http: HttpClient) { }

  //{{api_setting}}/v1/users/{{user_id}}/cities?size=2&status=A&page=1
  getListCitiesService(params: any): Observable<any> {
    const userId = sessionStorage.getItem('id');
    if (!userId) return;
    let hhtpParams: HttpParams = new HttpParams()
      .set("page", params ? params.page : 1)
      .set("size", params ? params.size : 100);
    if (params && params.geolocation) {
      hhtpParams = hhtpParams.set("geolocation", params.geolocation);
    }
    if (params && params.status) {
      hhtpParams = hhtpParams.set("status", params.status);
    }
    return this.http.get(`setting/${this.version}/users/${userId}/cities`, { params: hhtpParams });
  }
  // http://3.128.198.239:1002/api/setting
  updateCity(id: any, formData): Observable<any> {
    const userId = sessionStorage.getItem('id');
    if (!userId) return;
    return this.http.patch(`setting/${this.version}/users/${userId}/cities/${id}`, formData);
  }

  saveCity(formData): Observable<any> {
    const userId = sessionStorage.getItem('id');
    if (!userId) return;
    return this.http.post(`setting/${this.version}/users/${userId}/cities`, formData);
  }

  deleteCity(id: string): Observable<any> {
    const userId = sessionStorage.getItem('id');
    if (!userId) return;
    return this.http.delete(`setting/${this.version}/users/${userId}/cities/${id}`);
  }

}