<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Type Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button"  class="btn btn-primary" data-original-title="test"
                        data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="editCity(content)"><i class="fa fa-plus" aria-hidden="true"></i> Agregar</button>
                    </div>
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#Id</th>
                                    <th scope="col" sortable="product_name" (sort)="onSort($event)">Nombre</th>
                                    <th scope="col" (sort)="onSort($event)" >Descripción</th>
                                    <!-- <th scope="col" (sort)="onSort($event)" >Departamento</th> -->
                                    <th scope="col" class="tex-center">Estado</th>
                                    <th scope="col" class="tex-center">Opciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr  *ngFor="let city of tableItem$ | async; let i=index">
                                <th scope="row"><a style="color: #ff5370 !important;" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="editCity(content, city)">#{{ i +1 }}</a></th>
                                <td>{{city.name}}</td>
                                <td>{{city.description}}</td>
                                <td class="tex-center"><span class='badge' [ngClass]="{'badge-success': city.status == 1, 'badge-warning': city.status == 2  }">{{city.status == 1 ?  'Activo' : 'Inactivo' }}</span></td>
                                <td class="tex-center">
                                    <a ngbTooltip="Editar" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="editCity(content, city)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a ngbTooltip="Eliminar" style="cursor: pointer;" href="javascript:void(0)" (click)="deleteTypeEvents(city)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                   (pageChange)="getListCities($event)"
                                   [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                   </ngb-pagination>
                             </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content  let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModal">Agregar ciudad</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form" [formGroup]="form">
                <div class="form-group d-flex justify-content-end">
                    <app-switch-status [options]="{id:'statusCity', textCheck: 'Activo', textUnCheck: 'Inactivo'}" formControlName="status"></app-switch-status>
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Nombre:</label>
                    <input  formControlName="name" class="form-control " id="validationCustom01" type="text">
                    <div *ngIf="r.name.invalid && (r.name.dirty || r.name.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.name.errors?.required">
                               Ingrese el nombre
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="validationCustom01" class="mb-1">Departamento:</label>
                            <select formControlName="department"  (change)="getListGeolocations({type: 'province', code: $event.target.value})"  class="custom-select form-select">
                                <option value="">[ Seleccione ]</option>
                                <option *ngFor="let department of dataGeolocation['department']" [value]="department.department">{{department.name}}</option>
                            </select>
                            <div *ngIf="r.department.invalid && (r.department.dirty || r.department.touched || submitted)"
                                class="alert-form">
                                <div *ngIf="r.department.errors?.required">
                                        Seleccione el departamento
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="validationCustom01" class="mb-1">Provincia:</label>
                            <select (change)="getListGeolocations({type: 'district', code: $event.target.value })"  formControlName="province" class="custom-select form-select">
                                <option value="">[ Seleccione ]</option>
                                <option *ngFor="let province of dataGeolocation['province']" [value]="province.province">{{province.name}}</option>
                            </select>
                            <div *ngIf="r.province.invalid && (r.province.dirty || r.province.touched || submitted)"
                                class="alert-form">
                                <div *ngIf="r.province.errors?.required">
                                        Seleccione la provincia
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="validationCustom01" class="mb-1">Distrito:</label>
                            <select  formControlName="district" class="custom-select form-select">
                                <option value="">[ Seleccione ]</option>
                                <option *ngFor="let district of dataGeolocation['district']" [value]="district.district">{{district.name}}</option>
                            </select>
                            <div *ngIf="r.district.invalid && (r.district.dirty || r.district.touched || submitted)"
                                class="alert-form">
                                <div *ngIf="r.district.errors?.required">
                                        Seleccione el distrito
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Descripción:</label>
                    <div class="description-sm">
                        <textarea  formControlName="description"   class="form-control"  rows="5"></textarea>
                        <div *ngIf="r.description.invalid && (r.description.dirty || r.description.touched || submitted)"
                                class="alert-form">
                                <div *ngIf="r.description.errors?.required">
                                    Ingrese la descripción
                            </div>
                        </div>
                    </div>
                </div>

                <app-images (imageUpload)="imageUpload($event)" [dataImage]="dataImage"></app-images>

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="sedding" (click)="onFormSubmitted()" class="btn btn-primary"><i class="fa fa-floppy-o" aria-hidden="true"></i> GUARDAR</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>