import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { IGeolocationParams } from 'src/app/interface/generic/generic.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GenericService {


    private version = environment.apiVersion;
    // private userId = sessionStorage.getItem('id');

    constructor(private readonly http: HttpClient) { }

    //{{mock_api}}/v1/users/{{user_id}}/geolocations?code=1606&type=district
    getListGeolocationService(data: IGeolocationParams): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        if(data.code) {
            return this.http.get(`setting/${this.version}/users/${userId}/geolocations?code=${data.code}&type=${data.type}`);
        } else {
            return this.http.get(`setting/${this.version}/users/${userId}/geolocations?type=${data.type}`);
        }
    }

    //{{api_setting}}/v1/users/{{user_id}}/geolocations?code=82442903-eaa2-4dcc-bc49-907d4619209f&type=id
    getListGeolocationByIdService(id: string): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if(!userId) return;
        return this.http.get(`setting/${this.version}/users/${userId}/geolocations?code=${id}&type=id`);
    }

}