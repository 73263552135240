
import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from '../../../core/services/local/progress-bar.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent implements OnInit {

  loading: boolean = false;
  constructor(
    private _loading: ProgressBarService
  ) { }


  ngOnInit(): void {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

}
