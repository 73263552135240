import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2'


@Injectable({
  providedIn: 'root'
})
export class SweetalerService {

  constructor() { }


  success() {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500
    });
  }

  animate() {
    Swal.fire({
      title: "Custom animation with Animate.css",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    });
  }

  confirm(name: string): any {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-5",
        cancelButton: "btn btn-success m-5"
      },
      buttonsStyling: false
    });
  return  swalWithBootstrapButtons.fire({
      title: "Estas seguro de eliminar?",
      text: name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true
    });
    // .then((result) => {
    //   if (result.isConfirmed) {
    //     swalWithBootstrapButtons.fire({
    //       title: "Deleted!",
    //       text: "Your file has been deleted.",
    //       icon: "success"
    //     });
    //   } else if (
    //     /* Read more about handling dismissals below */
    //     result.dismiss === Swal.DismissReason.cancel
    //   ) {
    //     swalWithBootstrapButtons.fire({
    //       title: "Cancelled",
    //       text: "Your imaginary file is safe :)",
    //       icon: "error"
    //     });
    //   }
    // });
  }

  
}
