<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper">
    <a [routerLink]="'/dashboard/default'">
    <!-- <img class="blur-up lazyloaded"
        src="assets/images/dashboard/multikart-logo.png" alt=""> -->
      </a>
    </div>
</div>
<div class="sidebar custom-scrollbar">
  <!-- [attr.src]="profile?.avatar" (error)="$event.target.src = 'assets/images/dashboard/man.png'" -->
  <div *ngIf="profile" class="sidebar-user text-center">
    <div><img class="img-60 rounded-circle" src="assets/images/user.png"  alt="#">
    </div>
    <h6 class="mt-3 f-14">{{profile.name}}</h6>
    <!-- <p>{{profile.rolName}}</p> -->
  </div>
  <ul class="sidebar-menu">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
      <!-- Sub -->
      <a href="javascript:void(0)" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem)">
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ms-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- Link -->
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-header"
        *ngIf="menuItem.type === 'link'">
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ms-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>

      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
        *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i><span> {{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span> </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>



        </li>
      </ul>
    </li>
  </ul>
</div>