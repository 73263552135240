export const DATA_PARAMS: IDataParams = {
    size: 30,
    status: 'A',
    page: 1
}

export const DATA_PARAMS_ALL: IDataParams = {
    size: 300,
    status: 'A',
    page: 1
}

interface IDataParams {
    size: number,
    status: string,
    page: number
};