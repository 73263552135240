import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { UserFormService } from 'src/app/core/services/api/user/user-form.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { roles } from 'src/app/core/services/local/data-rol';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public form: FormGroup = this.userFormService.form;
  //satatus sedding
  submitted = false;
  sedding: boolean;
  DataRoles = roles;
  public active = 1;
  userId: string;
  constructor(
    private route: ActivatedRoute,
    protected readonly toast: ToastrService,
    private readonly toastrService: ToastrService,
    private readonly destroyRef: DestroyRef,
    private readonly userFormService: UserFormService,
    private readonly userService: UserService
  ) {
    this.userId = route.snapshot.params.id;
  }


  ngOnInit() {
    this.getUser();
  }

  get r() {
    return this.form.controls;
  }

  getUser() {
    this.userService.getListUserByIdService(this.userId).subscribe((res: any) => {
      // console.log(res);
      const [role] = this.DataRoles.filter((n: any) => n.code == res.role);
      // console.log(role, 'roles');
      if(role) {
        res.roleCode = role.id;
      } else {
        res.roleCode = "";
      }
      // this.service.setUserData(res)
      this.userFormService.setform(res);
    })
  }




  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    // console.log(this.form.value, 'this.form.value');

    this.submitted = false;
    const model = this.userFormService.saveForm();
    this.sedding = true;
    this.userService.updateUserService(this.userId, model)
      .pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((res: any) => {
        this.sedding = false;
        this.toastrService.success("Datos actualizados correctamente");
      });
  }
}
