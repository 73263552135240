import { createReducer, on } from '@ngrx/store'; //TODO <----

import { ITypeClubState } from '../model/type-cub.interface';
import { loadTypeClub, loadedTypeClub } from '../actions/type-club.actions';

//TODO: (2) Estado inicial!
export const initialState: ITypeClubState  = { loading: false, data: []};

//TODO: (3)
export const TypeClubReducer = createReducer(
  initialState,
  on(loadTypeClub, state => ({ ...state, loading: true })),
  on(loadedTypeClub, (state, {data}) => ( { ...state, loading: true, data }))
);
/**
export const scoreboardReducer = createReducer(
  initialState,
  on(ScoreboardPageActions.homeScore, state => ({ ...state, home: state.home + 1 })),
  on(ScoreboardPageActions.awayScore, state => ({ ...state, away: state.away + 1 })),
  on(ScoreboardPageActions.resetScore, state => ({ home: 0, away: 0 })),
  on(ScoreboardPageActions.setScores, (state, { game }) => ({ home: game.home, away: game.away }))
);
 */

// export const initialState: State = {
//   home: 0,
//   away: 0,
// };