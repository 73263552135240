

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from "@angular/common/http";
import { Observable, catchError, of, tap } from "rxjs";
import { IS_PUBLIC } from 'src/app/core/interceptor/auth.interceptor';
//interface
import { IRegister, RegisterResponse } from 'src/app/interface/auth';

import { environment } from 'src/environments/environment';
import { UpdateTokenService } from './update-tokens.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private readonly CONTEXT = { context: new HttpContext().set(IS_PUBLIC, true) };
  constructor(private readonly http: HttpClient) { }
  //{{mock_api}}/v1/users/{{user_id}}/credentials
  recoverPassword(data: any) {
    return this.http.post<RegisterResponse>(`security/${environment.apiVersion}/credentials`, data)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            // Handle invalid credentials
            // console.log('Invalid credentials');
          }
          return of();
        }),
        tap(data => {
          // console.log(data, 'data');
        })
      );
  }

//http://18.191.157.138:1001/api/security
  register(body: IRegister): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`security/${environment.apiVersion}/signup`, body, this.CONTEXT);
  }
}