
import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import { UpdateTokenService } from '../../services/api/auth/update-tokens.service';

export const authGuard: CanActivateFn = (route, state) => {

  const auht = inject(UpdateTokenService);
  // console.log(auht.isAuthenticated(), 'auht.isAuthenticated()');
  if (!auht.isAuthenticated()) {
    inject(Router).navigate(['auth/login']);
    return false;
  } 
  return true;
};



//addd