import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as jwt_decode from 'jwt-decode';

import { NavService } from '../../service/nav.service';
import { IMenu } from 'src/app/interface/menu.interface';
import { IProfile } from 'src/app/modules/acount/interface/account.interface';
import { roles } from 'src/app/core/services/local/data-rol';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public menuItems: IMenu[];
  public url: any;
  public fileurl: any;
  profile: IProfile;

  DataRoles = roles;


  constructor(private router: Router, public navServices: NavService) {


    this.navServices.items.subscribe(menuItems => {
      // console.log(menuItems, 'menuItems');
      // this.menuItems = menuItems
      this.menuToRole(menuItems);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })


    })
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    // this.getAccount();
    this.profile = JSON.parse(sessionStorage.getItem('entity'));
    /**
    console.log(this.profile, 'role');
    const [role] = this.DataRoles.filter((n: any) => n.code == this.profile.role);
    console.log(role, 'role');
    if(role) {
      this.profile.rolName = role.name;
    }
  */
  }

  /// filter to role
  menuToRole(menuItems) {

    const _token: string = sessionStorage.getItem('token');
    if (_token) {
      const data: any = jwt_decode.jwtDecode(_token);
      const { scopes } = data;
      if (!!scopes.length) {
        this.menuItems = menuItems.map((route) => {
          const { role } = route;
          const filterMenu = role.filter(x => scopes.includes(x));
          if (!!filterMenu.length) {
            return route;
          } else {
            null
          }
        }).filter(d => d);
      }
    }
  }

  // Active Nave state
  setNavActive(item) {
    if (!this.menuItems) {
      return;
    }
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  // readUrl(event: any) {
  //   if (event.target.files.length === 0)
  //     return;
  //   //Image upload validation
  //   var mimeType = event.target.files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }
  //   // Image upload
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     this.url = reader.result;
  //   }
  // }

  // getAccount() {
  //   this.accountService.getAccountService().subscribe((res: IAccount) => {
  //     // console.log(res, 'response data');
  //     this.profile= res.profile;
  //   });
  // }

}
