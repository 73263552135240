import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

//modules
import { SharedModule } from '../../shared/shared.module';

//compoennts
import { SvgImgComponent } from './svg-img/svg-img.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { LoginComponent } from './login/login.component';


@NgModule({
  declarations: [LoginComponent,
    RecoverPasswordComponent,
    SvgImgComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    CarouselModule,
    SharedModule,
    NgbModule
  ]
})
export class AuthModule { }
