
import { Component, DestroyRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, throwError } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { TableService } from 'src/app/shared/service/table.service';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CitiesService } from 'src/app/core/services/api/setting/cities.service';
import { ICity, IDataCity } from '../interfaces/cities.interface';

import { SweetalerService } from 'src/app/core/services/local/sweetaler.service';
import { GenericService } from 'src/app/core/services/api/generic/generic.service';
import { IGeolocationParams, IImage } from 'src/app/interface/generic/generic.interface';
import { ToastrService } from 'ngx-toastr';
import { DATA_PARAMS } from 'src/app/shared/data/params-table';


@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrl: './cities.component.scss',
  providers: [TableService, DecimalPipe],
})


export class CitiesComponent implements OnInit {
  public closeResult: string;
  searchText;
  tableItem$: Observable<ICity[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  public dataImage: IImage = {
    id: Number(new Date().valueOf()),
    type: "fotocard",
    urlImage: "./assets/images/img/no-image.png",
    labelImage: "Imagen 100X300:",
  };


  public form: FormGroup = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    description: ['', Validators.required],
    status: [false],
    department: ['', [Validators.required]],
    province: ['', [Validators.required]],
    district: ['', [Validators.required]],
  });

  public submitted: boolean;
  public sedding: boolean;

  //data geolocations
  public dataGeolocation = {
    department: [],
    province: [],
    district: []
  };

  image: File;
  statusOpenModal: boolean;

  constructor(public service: TableService,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly toast: ToastrService,
    private readonly sweetalerService: SweetalerService,
    private readonly citiesService: CitiesService,
    private readonly genericService: GenericService,
    private modalService: NgbModal) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
  }

  onSort({ column, direction }) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }


  editModel = (data: ICity): void => {
    if (data) {
      this.setImage(data);
      if (data.geolocation) {
        const params = {
          type: 'district',
          code: data.geolocation
        }
        this.getListGeolocationsEdit(params, data);
      }
    } else {
      this.resetModel();
      this.dataImage = {
        id: Number(new Date().valueOf()),
        type: "fotocard",
        urlImage: "./assets/images/img/no-image.png",
        labelImage: "Imagen 100X300:",
      };
    }
  }


  resetModel(data?: ICity) {
    const model = {
      id: '',
      name: '',
      description: '',
      status: false,
      department: '',
      province: '',
      district: ''
    }
    this.form.setValue({ ...model });
  }

  setImage = (data: ICity) => {
    this.image = null;
    if (data) {
      this.dataImage.id = Number(new Date().valueOf());
      this.dataImage.urlImage = `${data.frontpage}?${Math.random()}`;
    } else {
      this.dataImage.id = Number(new Date().valueOf());
      this.dataImage.urlImage = "./assets/images/img/no-image.png";
    }
  }

  async editCity(content, city?: ICity) {
    this.editModel(city);
    this.modalService.open(content, {
      ariaLabelledBy: 'exampleModal',
      size: 'lg',
      backdrop: "static",
      keyboard: false
    })
      .result.then((result) => {
      }, (reason) => {
        this.statusOpenModal = false;
      });
  }

  ngOnInit() {
    this.getListCities();
    this.getListGeolocations();
  }


  getListGeolocationsEdit(params, data) {
    this.genericService.getListGeolocationService(params)
      .subscribe((res: any) => {
        if (res) {
          this.getListGeolocations({ type: 'province', code: res.department });
          this.getListGeolocations({ type: 'district', code: `${res.department}${res.province}` });
          const model = {
            id: data.id,
            status: data.status == 1 ? true : false,
            name: data.name,
            description: data.description,
            department: res.department,
            province: res.province,
            district: res.district
          }
          this.form.setValue({ ...model });
        }
      });
  }


  getListGeolocations(dataOptions?: IGeolocationParams) {
    // code ubigeo six digits, or parent to get type geolocation, 
    // department: 2 digits to get specific department, and 0 digits all departments
    // province:  4 digits to get specific province and 2 digits all provinces.
    // district: 6 digits to get specific district and 4 digits to get all districts

    // type geolocation department, province, district, code
    let data: any;
    if (!dataOptions) {
      this.dataGeolocation['department'] = [];
      this.dataGeolocation['province'] = [];
      this.dataGeolocation['district'] = [];
      data = {
        code: '',
        type: 'department'
      }
    } else {
      this.dataGeolocation['district'] = [];
      this.form.get('district').patchValue("");
      if (dataOptions.type == 'district') {
        if (dataOptions.type == 'district' && this.form.value.department) {
          const lengthCode = (dataOptions.code.toString()).length;
          const code = lengthCode === 4 ? dataOptions.code : `${this.form.value.department}${dataOptions.code}`;
          data = { ...dataOptions, code };
        } else {
          data = { ...dataOptions };
        }
      } else if (dataOptions.type == 'province') {
        this.form.get('province').patchValue("");
        this.dataGeolocation['province'] = [];
        data = { ...dataOptions };
      }
    }


    this.genericService.getListGeolocationService(data).subscribe((res: any) => {
      if (!dataOptions) {
        this.dataGeolocation['department'] = res;
        return;
      }
      else {
        if (dataOptions.type == 'province') {
          this.dataGeolocation['province'] = res;
        } else {
          this.dataGeolocation['district'] = res;
        }
      }
    });
  }

  // 'department' | 'province' | 'district' |
  getListCities(page?: number) {
    const data = { ...DATA_PARAMS, page: page || 1 };
    this.citiesService.getListCitiesService(data)
      .subscribe((res: IDataCity) => {
        this.service.setUserData(res)
      });
  }

  imageUpload(event: any) {
    this.image = event;
  }

  formData = (payload: string): FormData => {
    const formData = new FormData();
    if (this.image) {
      formData.append('frontpage', this.image);
    }
    formData.append('payload', payload)
    return formData;
  }

  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;
    const formValue = this.form.value;
    const ubigeo = `${formValue.department}${formValue.province}${formValue.district}`;
    const model: ICity = {
      status: formValue.status ? 1 : 2,
      name: formValue.name,
      description: formValue.description,
      geolocation: ubigeo
    };
    const data = this.formData(JSON.stringify(model));
    this.sedding = true;
    // console.log('save');
    if (formValue.id) {
      this.citiesService.updateCity(formValue.id, data).pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
        this.getListCities();
        this.modalService.dismissAll();
      });
    } else {
      this.citiesService.saveCity(data).pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
        this.getListCities();
        this.modalService.dismissAll();
      });
    }
  }

  deleteTypeEvents(events: ICity) {
    this.sweetalerService.confirm(events.name)
      .then((result) => {
        if (result.isConfirmed) {
          this.citiesService.deleteCity(events.id).pipe(
            takeUntilDestroyed(this.destroyRef)
          ).subscribe((res: any) => {
            this.modalService.dismissAll();
            this.getListCities();
          });
        }
      });
  }

  get r() {
    return this.form.controls;
  }

}

