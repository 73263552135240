import { Component } from '@angular/core';

@Component({
  selector: 'app-svg-img',
  templateUrl: './svg-img.component.html',
  styleUrl: './svg-img.component.scss'
})
export class SvgImgComponent {
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  owlcarousel = [
    {
      title: "BIENVENIDO A PARTYNG",
      // desc: "Te ayudamos a gestionar todos los contenidos de eventos y actividades.",
    },
    // {
    //   title: "Welcome to Multikart",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
    // {
    //   title: "Welcome to Multikart",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // }
  ]

}
