import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { HashedValueService } from "../../local/hashed-value.service";

@Injectable({
    providedIn: "root",
})
export class UserFormService {

    constructor(private fb: FormBuilder, private readonly hashed: HashedValueService) { }

    public form: FormGroup = this.fb.group({
        id: ['', [Validators.required]],
        status: [false],
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        role: ['', [Validators.required]]
    });


    setform(data: any) {
        this.form.setValue({
            id: data && data.id ? data.id : "",
            status: data && data.status == 1 ? true : false,
            firstname: data && data.firstname ? data.firstname : "",
            lastname: data && data.lastname ? data.lastname : "",
            email: data && data.email ? data.email : "",
            role: data && data.roleCode ? data.roleCode : ""
        });
    }

    saveForm(): any {
        const formValue = this.form.value;
        return {
            firstname: formValue.firstname,
            lastname: formValue.lastname,
            email: formValue.email,
            role: Number(formValue.role),
            status: formValue.status ? 1 : 2
        }
    }
}


