// http.interceptor.ts

import {
  HttpRequest,
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ProgressBarService } from '../services/local/progress-bar.service';

export const progressBarInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const progress = inject(ProgressBarService);

  progress.setLoading(true, req.url);
  return next(req)
    .pipe(catchError((err) => {
      progress.setLoading(false, req.url);
      return err;
    }))
    .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      if (evt instanceof HttpResponse) {
        progress.setLoading(false, req.url);
      }
      return evt;
    }));

};