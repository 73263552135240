
import { Component, DestroyRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, throwError } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { TableService } from 'src/app/shared/service/table.service';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";

import { IDatatypeClubs, ITypeClub } from '../interfaces/clubs.interface';

//forms
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SweetalerService } from 'src/app/core/services/local/sweetaler.service';
import { TypeClubsService } from 'src/app/core/services/api/setting/type-clubs.service';
import { ToastrService } from 'ngx-toastr';
import { DATA_PARAMS } from 'src/app/shared/data/params-table';


@Component({
  selector: 'app-type-club',
  templateUrl: './type-club.component.html',
  styleUrl: './type-club.component.scss',
  providers: [TableService, DecimalPipe],
})


export class TypeClubComponent implements OnInit {
  searchText;
  tableItem$: Observable<ITypeClub[]>;
  total$: Observable<number>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  public form: FormGroup = this.fb.group({
    id: [''],
    status: [false],
    name: ['', [Validators.required]],
    description: ['', Validators.required]
  });

  //satatus sedding
  submitted = false;
  sedding: boolean;

  constructor(public service: TableService,
    private readonly fb: FormBuilder,
    private readonly toast: ToastrService,
    private modalService: NgbModal,
    private readonly destroyRef: DestroyRef,
    private readonly sweetalerService: SweetalerService,
    private readonly clubsService: TypeClubsService
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
  }

  onSort({ column, direction }) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  openModal(content, catgory?: ITypeClub) {
    this.form.setValue({
      id: catgory ? catgory.id : '',
      status: catgory && catgory.status ? true : false,
      name: catgory ? catgory.name : '',
      description: catgory ? catgory.description : ''
    });
    this.modalService.open(content, { ariaLabelledBy: 'exampleModal', size: 'lg', backdrop :"static", keyboard: false  })
      .result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }


  ngOnInit() {
    this.getListTypeClubs();
  }

  getListTypeClubs(page?: number) {
    const data = { ...DATA_PARAMS, page: page || 1 };
    this.clubsService.getListClubService(data)
      .subscribe((res: IDatatypeClubs) => {
        this.service.setUserData(res)
      });
  }

  ///status=A  activo 
  ///status=I inactivo

  // statusLis: 0 inactivo
  // statusLis: 1 activo

  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;
    const { id, name, description, status } = this.form.value;
    const model: ITypeClub = {
      status: status ? 1 : 2,
      name: name,
      description: description
    };

    this.sedding = true;
    if (id) {
      this.clubsService.updateTypeClub(id, model)
        .pipe(
          catchError((error: any) => {
            this.sedding = false;
            return throwError('Something went wrong');
          }),
          takeUntilDestroyed(this.destroyRef),
        ).subscribe((res: any) => {
          this.sedding = false;
          this.getListTypeClubs();
          this.modalService.dismissAll();
        });
    } else {
      this.clubsService.saveTypeClub(model).pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
        this.getListTypeClubs();
        this.modalService.dismissAll();
      });
    }
  }

  deleteCategory(catgory: ITypeClub) {
    this.sweetalerService.confirm(catgory.name)
      .then((result) => {
        if (result.isConfirmed) {
          this.clubsService.deleteTypeClub(catgory.id).pipe(
            catchError((error: any) => {
              // this.sedding = false;
              return throwError('Something went wrong');
            }),
            takeUntilDestroyed(this.destroyRef)
          ).subscribe((res: any) => {
            if (res.id) {
              this.getListTypeClubs();
            }
          });
        }
      });
  }


  get r() {
    return this.form.controls;
  }

}
