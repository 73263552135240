<!-- Container-fluid starts-->
<div class="container-fluid">
  <form class="digital-add needs-validation" [formGroup]="form" novalidate>
    <div class="row mb-2">
      <div class="col-12">
        <div class="pull-right">
          <button
            type="button"
            [disabled]="sedding"
            (click)="onFormSubmitted()"
            class="btn btn-primary"
          >
            <i class="fa fa-floppy-o" aria-hidden="true"></i> GUARDAR
          </button>
        </div>
      </div>
    </div>

    <div class="row product-adding">
      <div class="col-12 col-sm-12 col-md-6 col-xl-6 mb-2">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5>General</h5>
            <div class="pull-right">
              <app-switch-status [options]="{id:'statusEvent', textCheck: 'Activo', textUnCheck: 'Inactivo'}" formControlName="status"></app-switch-status>
            </div>
          </div>
          <div class="card-body">
            <div class="digital-add needs-validation">
              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Nombre:</label
                >
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="firstname"
                />
                <div
                  *ngIf="
                    r.firstname.invalid &&
                    (r.firstname.dirty || r.firstname.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.firstname.errors?.required">
                    Ingrese el nombre
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Apellidos:</label
                >
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="lastname"
                />
                <div
                  *ngIf="
                    r.lastname.invalid &&
                    (r.lastname.dirty || r.lastname.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.lastname.errors?.required">
                    Ingrese los apellidos
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Email:</label
                >
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    r.email.invalid &&
                    (r.email.dirty || r.email.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.email.errors?.required">Ingrese E-mail</div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Número de teléfono:</label
                >
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="cellphone"
                />
                <div
                  *ngIf="
                    r.cellphone.invalid &&
                    (r.cellphone.dirty || r.cellphone.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.cellphone.errors?.required">
                    Ingrese número de teléfono
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Dirección:</label
                >
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  formControlName="address"
                />
                <div
                  *ngIf="
                    r.address.invalid &&
                    (r.address.dirty || r.address.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.address.errors?.required">
                    Ingrese la dirección
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-xl-6 mb-2">
        <div class="card">
          <div class="card-header">
            <h5>Producto</h5>
          </div>

          <div class="card-body">
            <div class="digital-add needs-validation">
              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span> Monto:</label
                >
                <input
                  class="form-control"
                  id="validationCustom02"
                  type="number"
                  formControlName="price"
                />

                <div
                  *ngIf=" r.price.invalid && (r.price.dirty || r.price.touched || submitted) "
                  class="alert-form" >
                  <div *ngIf="r.price.errors?.required">Ingrese el monto</div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Núnero de clubes:</label
                >
                <div class="qty-box1">
                  <div class="input-group">
                    <i class="fa fa-minus btnGtr1" (click)="decrement()"></i>
                    <input
                     (change)="updateValue($event.target.value)"
                      class="touchspin1 text-center"
                      name="counter"
                      [value]="r.amount.value"
                      type="text"
                    />
                    <i class="fa fa-plus btnLess1" (click)="increment()"></i>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label" for=""
                  ><span>*</span>Fecha de inicio</label
                >
                <div class="input-group input-grp-p">
                  <input
                    class="datepicker-here form-control digits"
                    ngbDatepicker
                    formControlName="dateInit"
                    placeholder="dd-mm-yyyy"
                    (click)="f.toggle()"
                    #f="ngbDatepicker"
                    placement="top"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn calendar"
                      (click)="f.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>

                </div>

                <div
                  *ngIf="
                    r.dateInit.invalid &&
                    (r.dateInit.dirty || r.dateInit.touched || submitted)
                  "
                  class="alert-form"
                >
                  <div *ngIf="r.dateInit.errors?.required">
                    Ingrese la fecha de inicio
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-3">
          <div class="card-header">
            <h5>Contrato</h5>
          </div>

          <div class="card-body">
            <!-- <label class="col-form-label pt-0 ">Contato de servicio:</label> -->
            <div class="card-body dropzone-custom p-0 mb-3">
             <ngx-dropzone
                class="dropzone-border"
                [multiple]="false"
                accept=".doc,.docx, .pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                (change)="onSelect($event)"
              >
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">
                      Suelte el archivo aquí o haga clic para cargar.
                    </h4>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview
                  *ngFor="let f of files"
                  [removable]="true"
                  (removed)="onRemove(f)"
                >
                  <ngx-dropzone-label
                    >Contrato</ngx-dropzone-label
                  >
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Container-fluid Ends-->
