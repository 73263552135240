import { ActionReducerMap } from '@ngrx/store';
import { TypeClubReducer } from './ngxstore/reducer/type-club.reducer';
import { ITypeClubState } from './ngxstore/model/type-cub.interface';

//items

export interface AppState {
  typeClub: ITypeClubState
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
    typeClub: TypeClubReducer
};

