import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
export class DatesService {
    constructor() { }

    getDatePicker(dates): any {
        if (!dates) {
            return;
        }
        dates = moment(dates);
        return {
            day: dates.format("DD") * 1,
            month: dates.format("MM") * 1,
            year: dates.format("YYYY") * 1,
            newValue: "",
        };
    }

    getDatetime(dates) {
        if (!dates) {
            return;
        }
        return moment(dates).format("HH:mm"); //:ss");
    }

    setDateTimeMoment(dates, times) {
        if (!dates) {
            return;
        }
        const newDate = {
            day: dates.day,
            month: dates.month,
            year: dates.year,
            newValue: "",
        };
        const mdt = moment([newDate.year, newDate.month - 1, newDate.day]);
        // console.log(moment(mdt).format('YYYY-MM-DD'));
        return moment(moment(mdt).format("YYYY-MM-DD") + " " + times);
    }

    setDateTime(dates, times) {
        if (!dates) {
            return;
        }
        const newDate = {
            day: dates.day,
            month: dates.month,
            year: dates.year,
            newValue: "",
        };
        const mdt = moment([newDate.year, newDate.month - 1, newDate.day]);
        // console.log(moment(mdt).format('YYYY-MM-DD'));
        return moment(moment(mdt).format("YYYY-MM-DD") + " " + times).format(
            "YYYY-MM-DD HH:mm:ss"
        );
    }

    setDate(dates) {
        if (!dates) {
            return "";
        }
        const newDate = {
            day: dates.day,
            month: dates.month,
            year: dates.year,
            newValue: "",
        };
        const mdt = moment([newDate.year, newDate.month - 1, newDate.day]);
        return moment(mdt).format("YYYY-MM-DD");
    }

    setTime(times) {
        if (moment(times).isValid()) {
            return moment(times).format("HH:mm:ss");
        }
        return "";
    }
}
