import { Component, DestroyRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, throwError } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { TableService } from 'src/app/shared/service/table.service';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";

import { EventsTypeService } from 'src/app/core/services/api/setting/events-type.service';
import { IDataTypeEvents, ITypeEvents } from '../interfaces/events-type.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SweetalerService } from 'src/app/core/services/local/sweetaler.service';
import { ToastrService } from 'ngx-toastr';
import { DATA_PARAMS } from 'src/app/shared/data/params-table';


@Component({
  selector: 'app-event-category',
  templateUrl: './event-category.component.html',
  styleUrl: './event-category.component.scss',
  providers: [TableService, DecimalPipe],
})


export class EventCategoryComponent implements OnInit {
  public closeResult: string;
  searchText;
  tableItem$: Observable<ITypeEvents[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public form: FormGroup = this.fb.group({
    id: [''],
    status: [false],
    name: ['', [Validators.required]],
    description: ['', Validators.required]
  });
  submitted: boolean;
  sedding: boolean;


  constructor(public service: TableService,
    private modalService: NgbModal,
    private readonly toast: ToastrService,
    private readonly destroyRef: DestroyRef,
    private readonly fb: FormBuilder,
    private readonly sweetalerService: SweetalerService,
    private readonly eventsTypeService: EventsTypeService) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
  }

  onSort({ column, direction }) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }


  ngOnInit() {
    this.getListEvents();
  }

  openModal(content, events?: ITypeEvents) {
    this.form.setValue({
      id: events ? events.id : '',
      name: events ? events.name : '',
      description: events ? events.description : '',
      status: events &&  events.status  == 1 ? true : false
    });
    this.modalService.open(content, { ariaLabelledBy: 'exampleModal', size: 'lg', backdrop :"static", keyboard: false  })
      .result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }


  getListEvents(page?: number) {
    const data = { ...DATA_PARAMS, page: page || 1 };
    this.eventsTypeService.getListEventsTypeService(data)
      .subscribe((res: IDataTypeEvents) => {
        this.service.setUserData(res)
      });
  }



  onFormSubmitted() {
    this.submitted = true;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;

    const { id, name, description, status } = this.form.value;
    const model: ITypeEvents = {
      status: status ? 1 : 2,
      name: name,
      description: description
    };

    this.sedding = true;
    if (id) {
      this.eventsTypeService.updateTypeEvents(id, model).pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
        this.getListEvents();
        this.modalService.dismissAll();
      });
    } else {
      this.eventsTypeService.saveTypeEvents(model).pipe(
        catchError((error: any) => {
          this.sedding = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: any) => {
        this.sedding = false;
        this.getListEvents();
        this.modalService.dismissAll();
      });
    }

  }

  deleteTypeEvents(events: ITypeEvents) {
    this.sweetalerService.confirm(events.name)
      .then((result) => {
        if (result.isConfirmed) {
          this.eventsTypeService.deleteTypeEvents(events.id).pipe(
            takeUntilDestroyed(this.destroyRef)
          ).subscribe((res: any) => {
            this.modalService.dismissAll();
            this.getListEvents();
          });
        }
      });
  }


  get r() {
    return this.form.controls;
  }

}
