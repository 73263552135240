import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITypeClub } from 'src/app/modules/setting/interfaces/clubs.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeClubsService {

  private version = environment.apiVersion;
  private userId = sessionStorage.getItem('id');

  constructor(private readonly http: HttpClient) { }

  // {{mock_api}}/v1/users/{{user_id}}/clubes?size=0&status=I&page=0
  getListClubService(params?: { size: number, status: string, page: number }): Observable<any> {
    const userId = sessionStorage.getItem('id');
    if(!userId) return;
    if(!params) {
      params = {
        size: 100,
        status: 'A',
        page: 1
      }
    } 
    return this.http.get(`setting/${this.version}/users/${userId}/clubes?size=${params.size}&status=${params.status}&page=${params.page}`);
  }

 
   //{{mock_api}}/v1/users/{{user_id}}/clubes/{{club_id}}
  updateTypeClub(id: any, model: ITypeClub) {
    const userId = sessionStorage.getItem('id');
    if(!userId) return;
    return this.http.patch(`setting/${this.version}/users/${userId}/clubes/${id}`, model);
  }

  //{{mock_api}}/v1/users/{{user_id}}/clubes
  saveTypeClub(model: ITypeClub) {
    const userId = sessionStorage.getItem('id');
    if(!userId) return;
    return this.http.post(`setting/${this.version}/users/${userId}/clubes`, model);
  }

  //{{api_setting}}/v1/users/{{user_id}}/clubes/{{club_id}}
  deleteTypeClub(id: string) {
    const userId = sessionStorage.getItem('id');
    if(!userId) return;
    return this.http.delete(`setting/${this.version}/users/${userId}/clubes/${id}`);
  }
}
