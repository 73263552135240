import { HttpContextToken, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from "@angular/core";
import { UpdateTokenService } from '../services/api/auth/update-tokens.service';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const authSvc = inject(UpdateTokenService);
  const router = inject(Router);
  // request content type
  req.headers.set('Content-Type', 'application/json');
  if (req.context.get(IS_LOGIN)) {
    const body: any = req.body;
    const encoded = window.btoa(`${body.email}:${body.password}`);
    return next(req.clone({
      headers: req.headers.set('Authorization', `Basic ${encoded}`),
      body: null
    }));

  }
  if (req.context.get(IS_PUBLIC)) {
    return next(req);
  }
  if (authSvc.isAuthenticated()) { // cambiar para el auth
    const authRequest = addAuthorizationHeader(req);
    return next(authRequest);
  } else {
   router.navigate(['/auth/login']);
    /** 
    return refresh.refreshToken().pipe(
      switchMap(() => {
        const authRequest = addAuthorizationHeader(req);
        return next(authRequest);
      })
    );
    */
  }
};

const addAuthorizationHeader = (req: HttpRequest<any>) => {
  const token = sessionStorage.getItem('token');
  return req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`)
  });
};

export const IS_PUBLIC = new HttpContextToken(() => false);

export const IS_LOGIN = new HttpContextToken(() => false);