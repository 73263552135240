import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects'; //TODO <---
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
// import { ClubsService } from 'src/app/core/services/api/clubs/clubs.service';
import { TypeClubsService } from 'src/app/core/services/api/setting/type-clubs.service';

@Injectable()
export class TypeClubEffects {
  loadTypeClub$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Type club] Load'),
      mergeMap((action: any) =>
        this.clubsService.getListClubService().pipe(
          tap(d => { console .log(d, 'ahdkfhakdhfaksdf')}),
          map(
            (data: any) => ({
              type: '[Type club] Loaded success',
              data: data.items              ,
            }),
            catchError(() => EMPTY)
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private readonly clubsService: TypeClubsService,
  ) {}
}