import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//routes
import { content } from './shared/routes/content-routes';

//guards
// import { authGuard } from './core/guards/auth/auth.guard';

// components
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { accountGuard, authGuard } from './core/guards/auth';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { pathResolve } from './core/interceptor/path-resolve';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [accountGuard],
    children: content,
    data: {
      roles: ['superuser', 'partner', 'manager']
    }
  },
  {
    path: 'auth',
    // canActivate: [authGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    // component: LoginComponent,
  },
  {
    path: '**',
    resolve: {
      path: pathResolve
    },
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
