import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const basePathInterceptor: HttpInterceptorFn = (req, next) => {

  const requestWithBasepath = req.clone({
    url: `${environment.apiURL}/${req.url}`,
  });

  return next(requestWithBasepath);

};
