
import { DecimalPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, throwError } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssociateService } from 'src/app/core/services/api/associate/associate.service';
import { IAssociate, IDataAssociate } from '../../interface/associate.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SweetalerService } from 'src/app/core/services/local/sweetaler.service';
import { HashedValueService } from 'src/app/core/services/local/hashed-value.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator, regexPassword } from 'src/app/core/services/local/confirm-password';
import { PARTNER } from 'src/app/constant/type-credentials';
import { CrendentialsService } from 'src/app/core/services/api/auth/credentials.service';
import { DATA_PARAMS } from 'src/app/shared/data/params-table';

@Component({
  selector: 'app-associate-list',
  templateUrl: './associate-list.component.html',
  styleUrls: ['./associate-list.component.scss'],
  providers: [TableService, DecimalPipe],
})
export class AssociateListComponent implements OnInit {

  public form: FormGroup = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]]
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });

  public submitted: boolean;
  public sedding: boolean;

  /*** form filter */
  public formFilter: FormGroup = this.fb.group({
    email: ['', [Validators.email]],
    fullname: ['']
  });

  // public listClub: IDataClub[];
  /** password */
  public formPassword: FormGroup = this.fb.group({
    id: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]]
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });

  public submittedPassword: boolean;
  public seddingPassword: boolean;
  /** ends password */


  public tableItem$: Observable<IAssociate[]>;
  // public digital_categories = []
  closeResult: string;
  total$: Observable<number>;


  constructor(public service: TableService,
    private modalService: NgbModal,
    private readonly toast: ToastrService,
    private readonly sweetalerService: SweetalerService,
    private readonly associateService: AssociateService,
    private readonly hashed: HashedValueService,
    private readonly fb: FormBuilder,
    private readonly crendentialsService: CrendentialsService,
    private readonly toastrService: ToastrService,
    private readonly destroyRef: DestroyRef,
    private router: Router) {
    this.tableItem$ = service.tableItem$;
    // this.service.setUserData(DIGITALLIST)
    this.total$ = service.total$;

  }




  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  open(content) {
    this.form.reset();
    this.modalService.open(content, { size: 'lg', backdrop :"static", keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getListAssociate();
  }

  //#region associate
  getListAssociate(page?: number) {
    const filter = this.formFilter.value;
    const params = { ...DATA_PARAMS, page: page || 1, email: filter.email || "", fullname: filter.fullname || "" };
    // console.log(params, 'parasmmm');
    this.associateService.getListAssociateService(params).subscribe((res: IDataAssociate) => {
      this.service.setUserData(res || []);
    });
  }

  resetList() {
    this.formFilter.reset();
    this.getListAssociate(1);
  }

  get r() {
    return this.form.controls;
  }


  onFormSubmitted() {
    this.submitted = true;
    const model: IAssociate = this.form.value;
    if (!this.form.valid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }

    this.submitted = false;
    const saveModel: IAssociate = {
      firstname: model.firstname,
      lastname: model.lastname,
      email: model.email,
      password: this.hashed.hashRSA_SHA1(model.password),
    }
    this.sedding = true;
    this.associateService.saveAssociate(saveModel).pipe(
      catchError((error: any) => {
        this.sedding = false;
        return throwError('Something went wrong');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: any) => {
      this.sedding = false;
      if (res.id) {
        this.router.navigate(["/associate/detail", res.id]);
      }
      this.modalService.dismissAll();
    });
  }

  editAssociate(id) {
    this.router.navigate(["/associate/detail", id]);
  }

  deleteAssociate(club: IAssociate) {
    this.sweetalerService.confirm(`Associado: ${club.firstname} ${club.lastname}`)
      .then((result) => {
        if (result.isConfirmed) {
          // console.log("elimninar");
          this.associateService.deleteAssociate(club.id).pipe(
            takeUntilDestroyed(this.destroyRef)
          ).subscribe((res: any) => {
            this.toast.success("Se eliminó el asociado correctamente");
            this.modalService.dismissAll();
            this.getListAssociate();
          });
        }
      });
  }

  //#endregion

  //#region password

  get p() {
    return this.formPassword.controls;
  }

  openUpdatePassword(content, associate: IAssociate) {
    this.formPassword.reset();
    this.formPassword.get('id').patchValue(associate.id);
    this.modalService.open(content, { size: 'lg', backdrop :"static", keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveUserAcountPassword() {
    this.submittedPassword = true;
    const model = this.formPassword.value;
    if (!this.formPassword.valid) {
      return;
    }
    this.submittedPassword = false;
    const saveModel = {
      id: model.id,
      confirmPassword: this.hashed.hashRSA_SHA1(model.confirmPassword),
      password: this.hashed.hashRSA_SHA1(model.password),
    }
    this.seddingPassword = true;
    this.crendentialsService.updateCredentials(PARTNER, saveModel).pipe(
      catchError((error: any) => {
        this.sedding = false;
        return throwError('Something went wrong');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: any) => {
      this.seddingPassword = false;
      this.toast.success("Se actualizó la contraseña correctamente");
      this.modalService.dismissAll();
    });
  }

  //#endregion


  /*** download data */
  async dataUrlToFile(dataUrl: string, fileName?: string) {
    let extension: any = dataUrl.split('.');
    let name: any = dataUrl.split('/');
    extension = extension.slice(extension.length - 1, extension.length).join('.');
    name = name.slice(name.length - 1, name.length)
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    // return new File([blob], name || fileName + `.${extension}`, { type: extension });
    const url= window.URL.createObjectURL(blob);
    window.open(url)
  }


}
