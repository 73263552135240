
import { Routes } from '@angular/router';
import { MANAGER, PARTNER, SUPERUSER } from 'src/app/constant/type-credentials';
// import { SUPERUSER } from 'src/app/constant/roles.constant';
import { accountGuard, authGuard } from 'src/app/core/guards/auth';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    // canActivate: [accountGuard],
    data: {
      roles: [SUPERUSER, SUPERUSER, PARTNER],
      breadcrumb: "Mi Cuenta"
    }
  },
  {
    path: 'account',
    loadChildren: () => import('../../modules/acount/acount.module').then(m => m.AcountModule),
    // canActivate: [accountGuard],
    data: {
      roles: [MANAGER, SUPERUSER, PARTNER],
      breadcrumb: "Mi Cuenta"
    }
  },
  {
    path: 'associate',
    loadChildren: () => import('../../modules/associates/associate.module').then(m => m.AssociatesModule),
    // canActivate: [accountGuard],
    data: {
      roles: [MANAGER, SUPERUSER, PARTNER],
      breadcrumb: "Asociados"
    }
  },
  {
    path: 'clubs',
    loadChildren: () => import('../../modules/clubs/clubs.module').then(m => m.ClubsModule),
    // canActivate: [accountGuard],
    data: {
      roles: [MANAGER, SUPERUSER, PARTNER],
      breadcrumb: "Clubs"
    }
  },
  {
    path: 'events',
    loadChildren: () => import('../../modules/events/events.module').then(m => m.EventsModule),
    // canActivate: [accountGuard],
    data: {
      roles: [MANAGER, SUPERUSER, PARTNER],
      breadcrumb: "Events"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../modules/users/users.module').then(m => m.UsersModule),
    // canActivate: [accountGuard],
    data: {
      roles: [MANAGER],
      breadcrumb: "Users"
    }
  },
  {
    path: 'settings',
    canActivate: [accountGuard],
    loadChildren: () => import('../../modules/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings",
      roles: [SUPERUSER]
    }
  },
];