<div class="form-group block-g-img">
    <label>{{ dataImage.labelImage }} <em class="text-em">{{dataImage.sizeImage}}</em> </label>

    <label class="uploadImg pointer mrg-btm-30" [attr.for]="idHtml"> <!-- [attr.for]="img-g-dataImage.type}}" -->
        <figure>
            <img src="{{ dataImage.urlImage || ''}}" class="w150" (error)="onImgError($event)">
        </figure>
        <span class="btn btn-default display-block no-mrg-btm">Cargar
            imagen</span>
        <!-- <span *ngIf="sedding" disabled
            class="btn btn-default display-block no-mrg-btm"><i
                class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> Guardando...</span> -->
        <input #myAvatar
            (change)="selectImage($event.target.files[0])"  accept="image/png, image/jpeg, image/jpg"
            class="d-none upload-img" type="file" name="uploadedimages10"
            multiple="" [id]="idHtml">
    </label>
</div>