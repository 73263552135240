import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {


    private version = environment.apiVersion;
    // private userId = sessionStorage.getItem('id');
    constructor(private readonly http: HttpClient) { }

    //{{api_security}}/v1/users/{{user_id}}/users
    getListUserService(params: { size: number; page: number; email?: string, fullname?: string }) {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        let hhtpParams: HttpParams = new HttpParams()
            .set("page", params ? params.page : 1)
            .set("size", params ? params.size : 100);
        if (params && params.email) {
            hhtpParams = hhtpParams.set("email", params.email);
        }
        if (params && params.fullname) {
            hhtpParams = hhtpParams.set("fullname", params.fullname);
        }
        return this.http.get(`user/${this.version}/users/${userId}/users`, { params: hhtpParams });
    }

    // {{api_user}}/v1/users/{{user_id}}/users/{{users_id}}
    getListUserByIdService(id: any) {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.get(`user/${this.version}/users/${userId}/users/${id}`);
    }

    // {{api_user}}/v1/users/{{user_id}}/users
    saveUserClub(model: any): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.post(`user/${this.version}/users/${userId}/users`, model);
    }

    // {{api_user}}/v1/users/{{user_id}}/users/{{users_id}}
    updateUserService(id: string, model: any) {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.patch(`user/${this.version}/users/${userId}/users/${id}`, model);
    }

    updateCredentials(id, saveModel: { confirmPassword: string; password: string; }): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.patch(`user/${this.version}/users/${userId}/users/${id}`, saveModel);
    }

    //{{api_user}}/v1/users/{{user_id}}/users/{{users_id}}
    deleteUserService(id: string): Observable<any> {
        const userId = sessionStorage.getItem('id');
        if (!userId) return;
        return this.http.delete(`user/${this.version}/users/${userId}/users/${id}`);
    }
}