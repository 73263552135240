
import { Injectable, WritableSignal, signal } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as jwt_decode from 'jwt-decode';

import * as moment from "moment";
import { IUser } from "src/app/interface/auth";


@Injectable({ providedIn: 'root' })

export class UpdateTokenService {

    constructor(private readonly jwtHelper: JwtHelperService) { }

    get user(): WritableSignal<IUser | null> {
        const token = sessionStorage.getItem('token');
        return signal(token ? this.jwtHelper.decodeToken(token) : null);
    }

    isAuthenticated(): boolean {
        const _token: string = sessionStorage.getItem('token');
        if (_token) {
            return !this.jwtHelper.isTokenExpired(_token);
        }
        return false;
    }

    decodeRoles = (): string[]  => {
        const _token: string = sessionStorage.getItem('token');
        const data: any = jwt_decode.jwtDecode(_token);
        const { scopes, sub } = data;
        return scopes;
    }
    
    hasAnyRole(allowedRoles: string[]) {
        const scopes = this. decodeRoles();
        const filterMenu = allowedRoles.filter(x => scopes.includes(x));
        return !!filterMenu.length;
    }


    setTokens = (data: any): void => {
        const { token } = data;
        const expiresAt = moment(token.expires_at); 

        sessionStorage.setItem('token', token.access);
        sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
        sessionStorage.setItem('id', data.entity.id);
        sessionStorage.setItem("entity", JSON.stringify(data.entity));
        
        const [scopes] = this.decodeRoles();
        sessionStorage.setItem("roles", scopes);
    }

    getToken = (): string => {
        return sessionStorage.getItem('token');
    }

}

