
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from "@angular/core";
import { UpdateTokenService } from '../../services/api/auth/update-tokens.service';
import { ToastrService } from 'ngx-toastr';

export const accountGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const auth = inject(UpdateTokenService);
  const toast = inject(ToastrService);
  const allowedRoles = next.data.roles as Array<string>;
  // console.log(allowedRoles, next, 'allowedRoles');
  if (!auth.hasAnyRole(allowedRoles)) {
    inject(Router).navigate(['/auth/login']);
    toast.error("Ud no tiene acceso, comunicate con el administrador del sistema!!!");
    sessionStorage.clear();
    return false;
  }
  return true;
};