<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               <div class="card-header">
                    <div class="row">
                        <div class="col-10">
                            <form [formGroup]="formFilter" class="needs-validation">
                                <div class="row">
                                    <div class="col-9 d-flex">
                                        <div class="form-group m-1 flex-fill">
                                            <input formControlName="fullname" class="form-control" id="filteruser" placeholder="Ingrese el asociado" type="text">
                                        </div>
                                        <div class="form-group m-1 flex-fill">
                                            <input formControlName="email" class="form-control" id="filteremail" placeholder="Ingrese E-mail" type="text">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button (click)="getListAssociate()" type="button" class="btn btn-secondary m-1"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        <button (click)="resetList()" type="button" class="btn btn-secondary m-1"><i class="fa fa-paint-brush" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-2">
                            <div class="pull-right m-1">
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                                    data-target="#procudct-add-modal" (click)="open(contentNewAssociate)"><i class="fa fa-plus" aria-hidden="true"></i> Agregar</button>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card-body">
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="fName" (sort)="onSort($event)">#Id</th>
                                        <th scope="col" sortable="fName" (sort)="onSort($event)">Nombre</th>
                                        <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                                        <th class="tex-center" scope="col" sortable="role" (sort)="onSort($event)"> Nro clubes</th>
                                        <th scope="col" sortable="role" (sort)="onSort($event)">Estado</th>
                                        <th class="tex-center" scope="col" >Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tableItem$ | async; let i=index ">
                                        <td><a class=""  [routerLink]="['/associate/detail', item.id]">#{{ i + 1  }}</a></td>
                                        <td>
                                            {{item.firstname}} {{item.lastname}}
                                        </td>
                                        <td>{{item.email}}</td>
                                        <td class="tex-center">-----</td>
                                        <td><span class='badge' [ngClass]="{'badge-success': item.status == 1, 'badge-warning': item.status != 1  }">{{item.status == 1 ?  'Activo' : 'Inactivo' }}</span></td>
                                        <td class="tex-right">
                                            <a *ngIf="item?.contract?.file" [attr.href]="item?.contract?.file" target="_blank" ngbTooltip="Descargar"  download title="Descargar contrato"><i class="fa fa-download fa-default"></i></a>&nbsp;
                                            <a ngbTooltip="Cambiar contraseña"  (click)="openUpdatePassword(contentUserPassword, item)"  data-target="#procudct-add-user-password" href="javascript:void(0)"><i class="fa fa-unlock-alt fa-default" aria-hidden="true"></i></a>&nbsp;
                                            <a ngbTooltip="Editar" (click)="editAssociate(item.id)" href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                            <a ngbTooltip="Eliminar"  (click)="deleteAssociate(item)" href="javascript:void(0)"><i class="fa fa-trash-o"></i></a> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                (pageChange)="getListAssociate($event)"
                                [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #contentNewAssociate let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="procudct-add-modal">Agregar Asociado</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" class="needs-validation">
            <div class="form">

                <div class="form-group">
                    <label for="validationCustom01" class="mb-1">Nombre:</label>
                    <input formControlName="firstname" class="form-control" id="validationCustom01" type="text">
                    <div *ngIf="r.firstname.invalid && (r.firstname.dirty || r.firstname.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.firstname.errors?.required">
                                Ingrese el nombre
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom02" class="mb-1">Apellidos:</label>
                    <input formControlName="lastname" class="form-control" id="validationCustom02" type="text">
                    <div *ngIf="r.lastname.invalid && (r.lastname.dirty || r.lastname.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.lastname.errors?.required">
                                Ingrese los apellidos
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom03" class="mb-1">Email :</label>
                    <input formControlName="email" class="form-control" id="validationCustom03" type="text">
                    <div *ngIf="r.email.invalid && (r.email.dirty || r.email.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.email.errors?.required">
                                Ingrese el E-mail
                        </div>
                    </div>
                </div>
  
                <div class="form-group">
                    <label for="validationCustom06" class="mb-1">Password :</label>
                    <input formControlName="password" class="form-control" id="validationCustom06" type="password">
                    <div *ngIf="r.password.invalid && (r.password.dirty || r.password.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.password.errors?.required">
                                Ingrese password
                        </div>
                        <div *ngIf="r.password.errors?.['pattern']">
                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom07" class="mb-1">Confirmar password :</label>
                    <input formControlName="confirmPassword" class="form-control" id="validationCustom07" type="password">
                    <div *ngIf="r.confirmPassword.invalid && (r.confirmPassword.dirty || r.confirmPassword.touched || submitted)"
                        class="alert-form">
                        <div *ngIf="r.confirmPassword.errors?.required">
                                Ingrese confirmar password
                        </div>
                        <div *ngIf="r.confirmPassword.errors?.['pattern']">
                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                        </div>
                        <div *ngIf="r.confirmPassword.errors?.['confirmedValidator']">
                            Las contraseñas no coinciden
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button  [disabled]="sedding" (click)="onFormSubmitted()" type="button" type="button" class="btn btn-primary"><i class="fa fa-floppy-o" aria-hidden="true"></i> Guardar</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>


<!-- Container-fluid Ends-->
<ng-template #contentUserPassword let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="procudct-add-user-password">Cambiar contraseña</h5>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formPassword" class="needs-validation">
            <div class="form">

                <div class="form-group">
                    <label for="validationCustom01" class="mb-1"> Password:</label>
                    <input formControlName="password" class="form-control" id="validationCustom01" type="password">
                    <div *ngIf="p.password.invalid && (p.password.dirty || p.password.touched || submittedPassword)"
                    class="alert-form">
                    <div *ngIf="p.password.errors?.required">
                            Ingrese password
                    </div>
                    <div *ngIf="p.password.errors?.['pattern']">
                        Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                    </div>
                </div>
                </div>
                <div class="form-group">
                    <label for="validationCustom02" class="mb-1">Confirmar password :</label>
                    <input formControlName="confirmPassword" class="form-control" id="validationCustom02" type="password">
                    <div *ngIf="p.confirmPassword.invalid && (p.confirmPassword.dirty || p.confirmPassword.touched || submittedPassword)"
                        class="alert-form">
                        <div *ngIf="p.confirmPassword.errors?.required">
                                Ingrese confirmar password
                        </div>
                        <div *ngIf="p.confirmPassword.errors?.['pattern']">
                            Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                        </div>
                        <div *ngIf="p.confirmPassword.errors?.['confirmedValidator']">
                            Las contraseñas no coinciden
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button [disabled]="seddingPassword" (click)="saveUserAcountPassword()" type="button" class="btn btn-primary"><i class="fa fa-floppy-o" aria-hidden="true"></i> GUARDAR</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Cerrar</button>
    </div>
</ng-template>