import { Component, OnInit } from '@angular/core';
import { SUPERUSER } from 'src/app/constant/type-credentials';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
   public home: boolean;
 
  constructor() {
    this.home = sessionStorage.getItem('roles') == SUPERUSER ? true : false;
  }

  ngOnInit() {
  }

}
