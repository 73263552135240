
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { IImage } from "src/app/interface/generic/generic.interface";

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrl: './images.component.scss'
})
export class ImagesComponent implements OnInit, OnDestroy {
  @Input() dataImage: IImage;
  @Output() imageUpload = new EventEmitter<File>();
  @ViewChild("myAvatar") myAvatar: ElementRef;

  public idHtml = "000";
  constructor(
    private toast: ToastrService
  ) { }
  ngOnInit() {
    this.idHtml = this.dataImage.type || Math.random() + "_" + this.dataImage.id;
    this.resetImage();
  }

  // upload immage generic
  selectImage(file: File) {
    if (!file) {
      this.toast.error("No hay imagen", "Error");
      this.resetImage();
      return;
    }
    if (file.type.indexOf("image") < 0) {
      this.toast.error("El archivo seleccionado no es una imagen", "Error");
      this.resetImage();
      return;
    }
    // this.uploadImage(file);
    this.updateReader(file);
    this.imageUpload.next(file);
  }

  // update reader images
  updateReader(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.dataImage.urlImage = reader.result;
    };
  }

  // resetear files
  resetImage() {
    if (this.myAvatar && this.myAvatar.nativeElement) {
      this.myAvatar.nativeElement.value = "";
    }
  }

  onImgError(event){
    event.target.src = "./assets/images/img/no-image.png";
   }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.resetImage();
  }
}
