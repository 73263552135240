import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class RSAHelperService {

  constructor() { }


  private readonly  publicKey = `-----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA5Ra9c38HWnL4I4EflEk/
  8D+aG/8ACsZBP84MYb2eHgkzdMcvTaTq++aH9aoWj1huYJ5HCegN2uu3ll5Alszp
  ye78Pp6FjvCRzN9k8WO4walitazSEqo5hxIWJosRJxpg/0MksbkJ3OR707bz6D9b
  3q2JBFkMX92eCotz9QgtKhqhWsocnwdedPxvy4CJ0JcQIyI8izAUh6mwhqZFR28A
  DUhOnfYrN7AAsjRWaqpjBlYO7h7PLyhEAFt4P3hjBJAFlBXzl2EImg8JlAA6S7Qf
  4GUkBIP269/CWfqqAOavdufTWvFjvA0yxnk8atFhQiL/51ySv8gYKQ3DLDt0J6cX
  MzfQsEP2taL2Gq/724Lkd5cMYc5WBUDRFLZIZAT8KumgcslsCc7jxRHmxar0jH8Y
  FgadH5U4GwLe8drhpBs+KLTD7xZ4oR5u7EnuEifvbrJ1pSqQuqSfOZJvu5+665ta
  1bgqUn0XEp6wadQP14PuLhmxprWYrvLUeKM3b9AjPuW0JAua03IdRibb3ZZA4E57
  uAj7poCrjuIKGyczI/RC0RH+Ltie5gDpTSMwTQ/5vTB2P4q1FlZdehnx09WnVOwx
  sDWvOB1ytiQtDWTfrrg3DesB9jF9JkuUkihxD6ZM9bOKS+lDxodFsyaLKRDZ4N/y
  9wKdVzIzGQy+WGrCsA5Sw9ECAwEAAQ==
  -----END PUBLIC KEY-----`;
 

  encryptWithPublicKey(valueToEncrypt: string): string {
    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }
}
