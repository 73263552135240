import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CrendentialsService {

    private version = environment.apiVersion;
    constructor(private readonly http: HttpClient) { }
    // {{api_security}}/v1/credentials/{{user_id}}
    updateCredentials(type, model: { id: string, confirmPassword: string; password: string; }): Observable<any> {
        const header = new HttpHeaders({ 'x-flow-type': type });
        return this.http.put(`security/${this.version}/credentials/${model.id}`, { ...model, id: null }, { headers: header });
    }


}