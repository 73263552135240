<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <app-svg-img></app-svg-img>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-header">
                            <h5 style="color: gray; text-transform: uppercase; text-align: center;">Cambiar contraseña</h5>
                        </div>
                        <div class="card-body">
                                <form [formGroup]="form" 
                                    class="form-horizontal auth-form" novalidate="">
                                    <div class="form-group">
                                        <input formControlName="email" name="login[email]" type="email"
                                            class="form-control" placeholder="Email"
                                            id="exampleInputEmail1">
                                            <div *ngIf="c.email.invalid && (c.email.dirty || c.email.touched || submitted)"
                                                class="alert">
                                                <div *ngIf="c.email.errors?.required">
                                                    Email es requerido
                                                </div>
                                                <div *ngIf="c.email.errors?.email">
                                                    Email incorrecto
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group">
                                        <input formControlName="password" name="login[password]" type="password"
                                            class="form-control" placeholder="Password">
                                            <div *ngIf="c.password.invalid && (c.password.dirty || c.password.touched || submitted)"
                                                class="alert">
                                                <div *ngIf="c.password.errors?.required">
                                                    Password es requerido
                                                </div>
                                                <div *ngIf="c.password.errors?.['pattern']">
                                                    Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                                                </div>
                                            </div>
                                    </div>
                                    <div class="form-group">
                                        <input formControlName="confirmPassword" name="login[confirmPassword]" type="password"
                                            class="form-control" placeholder="Confirm Password">
                                            <div *ngIf="c.confirmPassword.invalid && (c.confirmPassword.dirty || c.confirmPassword.touched || submitted)"
                                                class="alert">
                                                <div *ngIf="c.confirmPassword.errors?.required">
                                                    Confirmar password es requerido
                                                </div>
                                                <div *ngIf="c.confirmPassword.errors?.['pattern']">
                                                    Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 o más caracteres
                                                </div>
                                                <div *ngIf="c.confirmPassword.errors?.['confirmedValidator']">
                                                    Las contraseñas no coinciden
                                                </div>
                                            </div>
                                        </div>
                                    <div class="form-button">
                                        <button [disabled]="sedding" (click)="onFormSubmitted()" class="btn btn-primary" type="button">Recuperar</button>
                                        <a href="javascript:void(0)" [routerLink]="['/auth/login']" class="btn btn-default">Iniciar sesión</a>
                                        <!-- <button [disabled]="sedding" (click)="onFormSubmitted()" class="btn btn-primary" type="button">Recuperar</button> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>